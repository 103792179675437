import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import cx from "classnames";
import throttle from "lodash/throttle";
import "./GlobalHeader.scss";
import Button from "../../Elements/Button";
import OpeningTimesSingle from "../../Elements/OpeningTimesSingle";
import Today from "../Today";
import Directory from "../Directory";
import GuestServices from "../GuestServices";
import SearchBar from "../SearchBar";
import { ReactComponent as Bell } from "../../../Assets/icons/bell-icon-black.svg";
import { ReactComponent as DirectoryIcon } from "../../../Assets/icons/directory-icon-black.svg";
import { ReactComponent as Calender } from "../../../Assets/icons/calendar-icon-white.svg";
import { ReactComponent as Avatar } from "../../../Assets/icons/login-avatar-black.svg";
import { ReactComponent as Search } from "../../../Assets/icons/search-icon-white.svg";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-orange.svg";
import logo from "../../../Assets/RWB_red_logo.png";
import config from "../../../Config";
import ATREEMOSTORE from "../../../Stores/AtreemoStore";
import Helpers from "../../../lib/HelperUtils";

@observer
class GlobalHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CurrentPage: "/",
      NavOpen: false,
      TodayOpen: false,
      DirectoryOpen: false,
      GuestServicesOpen: false,
      SearchOpen: false,
      heightRef: React.createRef(),
      headerHeight: 0,
    };

    this.throttleWindow = throttle(() => {
      this.setHeadHeight();
    }, 200);
  }

  componentDidMount() {
    window.addEventListener("resize", this.throttleWindow);
    this.setHeadHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttleWindow);
  }

  /**
   * Get the url for the navigation item
   *
   * @param {object} item The current navigation item object
   */
  getItemUrl = (item) => {
    const itemUrl = {
      uri: "not-found",
      isExternal: false,
    };

    // Get either the external link or the entry uri
    if (item.buttonEntry.length !== 0 || item.externalLink !== null) {
      // Have entry url
      itemUrl.uri = item.buttonEntry.length !== 0 && item.buttonEntry[0].uri;

      // Have external link - Override entry url
      if (item.externalLink !== null) {
        itemUrl.uri = item.externalLink;
        itemUrl.isExternal = true;
      }

      // If homepage set url to empty string for routing
      if (itemUrl.uri === "homepage") {
        itemUrl.uri = "";
      }
    }

    return itemUrl;
  };

  setHeadHeight = () => {
    const { state } = this;
    const { heightRef } = this.state;
    this.setState(() => ({
      ...state,
      headerHeight: heightRef.current.offsetHeight,
    }));
  };

  OpenMenu = () => {
    const { state } = this;
    const { TodayOpen, DirectoryOpen, GuestServicesOpen } = this.state;
    if (TodayOpen) {
      this.setState((prevState) => ({
        ...state,
        NavOpen: !prevState.NavOpen,
        TodayOpen: !prevState.TodayOpen,
      }));
      this.toggleNoscroll(state.TodayOpen);
    } else if (DirectoryOpen) {
      this.setState((prevState) => ({
        ...state,
        NavOpen: !prevState.NavOpen,
        DirectoryOpen: !prevState.DirectoryOpen,
      }));
      this.toggleNoscroll(state.DirectoryOpen);
    } else if (GuestServicesOpen) {
      this.setState((prevState) => ({
        ...state,
        NavOpen: !prevState.NavOpen,
        GuestServicesOpen: !prevState.GuestServicesOpen,
      }));
      this.toggleNoscroll(state.GuestServicesOpen);
    } else {
      this.setState((prevState) => ({
        ...state,
        NavOpen: !prevState.NavOpen,
      }));
    }
  };

  closeMenu = () => {
    const { state } = this;
    const { TodayOpen, DirectoryOpen, GuestServicesOpen } = this.state;
    if (TodayOpen) {
      this.setState({
        ...state,
        NavOpen: false,
        TodayOpen: false,
      });
      this.toggleNoscroll(state.TodayOpen);
    } else if (DirectoryOpen) {
      this.setState({
        ...state,
        NavOpen: false,
        DirectoryOpen: false,
      });
      this.toggleNoscroll(state.DirectoryOpen);
    } else if (GuestServicesOpen) {
      this.setState({
        ...state,
        NavOpen: false,
        GuestServicesOpen: false,
      });
      this.toggleNoscroll(state.GuestServicesOpen);
    } else {
      this.setState({
        ...state,
        NavOpen: false,
      });
    }
  };

  toggleGuestServices = () => {
    const { state } = this;
    if (window.location.href.indexOf("/guest-services") > -1) {
      window.location.href = "/";
    } else {
      this.setState((prevState) => ({
        ...state,
        DirectoryOpen: false,
        GuestServicesOpen: !prevState.GuestServicesOpen,
        TodayOpen: false,
      }));
      this.toggleNoscroll(state.GuestServicesOpen);
    }
  };

  toggleDirectory = () => {
    const { state } = this;
    if (window.location.href.indexOf("/directory") > -1) {
      window.location.href = "/";
    } else {
      this.setState((prevState) => ({
        ...state,
        DirectoryOpen: !prevState.DirectoryOpen,
        GuestServicesOpen: false,
        TodayOpen: false,
      }));
      this.toggleNoscroll(state.DirectoryOpen);
    }
  };

  toggleToday = () => {
    const { state } = this;
    if (window.location.href.indexOf("/today") > -1) {
      window.location.href = "/";
    } else {
      this.setState((prevState) => ({
        ...state,
        DirectoryOpen: false,
        GuestServicesOpen: false,
        TodayOpen: !prevState.TodayOpen,
      }));
      this.toggleNoscroll(state.TodayOpen);
    }
  };

  toggleNoscroll = (toggle) => {
    if (toggle) {
      document.documentElement.classList.remove("noscroll");
    } else {
      document.documentElement.classList.add("noscroll");
    }
  };

  // togglePlaceholder = () => {
  //   console.log('click');
  // }

  toggleSearch = () => {
    this.setState((prevState) => ({
      SearchOpen: !prevState.SearchOpen,
    }));
  };

  render() {
    const { NavigationItems } = this.props;
    const {
      // OpeningTimes,
      NavOpen,
      TodayOpen,
      DirectoryOpen,
      GuestServicesOpen,
      SearchOpen,
      heightRef,
      headerHeight,
    } = this.state;
    const { loggedIn } = ATREEMOSTORE;

    const noTime = window.location.pathname !== "/" ? "noTime" : "";

    return (
      <React.Fragment>
        <header className={`Header ${noTime}`} ref={heightRef}>
          <a id="skiptocontent" href="#maincontent">
            Skip to main content
          </a>
          <div className="Header__top">
            <Link
              to="/"
              className="Header__logolink"
              onClick={
                this.toggleDirectory &&
                this.toggleGuestServices &&
                this.closeMenu
              }
            >
              <img
                src={Helpers.transformUrlCheck(logo)}
                alt="logo"
                className="Logo"
              />
            </Link>
            <div className="Header__wrap">
              <div className="Opening">
                <OpeningTimesSingle
                  Text="Shop Opening Times: "
                  url={`${config.contentEndpoint}/hours/single/shopHomepage.json`}
                />
                <NavLink
                  to="/opening-hours"
                  className="Opening__link"
                  onClick={this.closeMenu}
                >
                  <span>See All</span>
                </NavLink>
              </div>
              <Button
                isLink={false}
                priority="secondary"
                rounded={false}
                label=""
                onClick={this.toggleGuestServices}
                classes={cx("GlobalHeader__button", "Guest", {
                  active: GuestServicesOpen,
                })}
              >
                <span>Guest Services</span>
                <Bell />
              </Button>
              <Button
                isLink={false}
                priority="secondary"
                rounded={false}
                label=""
                onClick={this.toggleDirectory}
                classes={cx("GlobalHeader__button", "Directory", {
                  active: DirectoryOpen,
                })}
              >
                <span>Directory</span>
                <DirectoryIcon />
              </Button>
              <Button
                isLink={false}
                label=""
                rounded={false}
                onClick={this.toggleToday}
                classes={cx("GlobalHeader__button", "Today", {
                  active: TodayOpen,
                })}
              >
                <span>Today</span>
                <Calender />
              </Button>
              <Button
                isLink={false}
                label=""
                rounded={false}
                classes="GlobalHeader__button Search"
                onClick={this.toggleSearch}
              >
                <Search />
              </Button>

              {!loggedIn ? (
                <Button
                  label=""
                  color="grey"
                  rounded={false}
                  classes="GlobalHeader__button Login"
                  url="/login"
                >
                  <span>Login / Signup</span>
                  <Avatar />
                </Button>
              ) : (
                <Button
                  label=""
                  priority="secondary"
                  color="orange"
                  rounded={false}
                  classes="GlobalHeader__button MyAccount"
                  url="/my-account"
                >
                  <span>My Account</span>
                  <Avatar />
                </Button>
              )}

              <button type="button" className="Menu" onClick={this.OpenMenu}>
                <span
                  className={NavOpen ? "Menu__toggle active" : "Menu__toggle"}
                >
                  <span className="Menu__toggle-line-1" />
                  <span className="Menu__toggle-line-2" />
                  <span className="Menu__toggle-line-3" />
                </span>
              </button>
            </div>
          </div>
          <div className={NavOpen ? "Header__bottom active" : "Header__bottom"}>
            <ul className="Mobile__Links">
              <li className="Mobile__Today">
                <button type="button" onClick={this.toggleToday}>
                  <span>Today</span>
                </button>
              </li>
              <li
                className={cx("Mobile__Guest", { inactive: GuestServicesOpen })}
              >
                <button type="button" onClick={this.toggleGuestServices}>
                  <span>Guest Services</span>
                </button>
              </li>
              <li
                className={cx("Mobile__Directory", { active: DirectoryOpen })}
              >
                <button type="button" onClick={this.toggleDirectory}>
                  <span>Directory</span>
                </button>
              </li>
            </ul>
            <ul className="Nav">
              {NavigationItems.map((item) => {
                // Get the urls for this item
                const itemUrl = this.getItemUrl(item);

                // return the item
                return (
                  <li key={item.id} className="Nav__item">
                    {itemUrl.isExternal ? (
                      <a
                        className="Nav__link"
                        href={`${itemUrl.uri}`}
                        rel="noopener noreferrer"
                        exact
                      >
                        <Chevron className="Nav__icon" />
                        <span>{item.heading}</span>
                      </a>
                    ) : (
                      <NavLink
                        className="Nav__link"
                        to={`/${itemUrl.uri}`}
                        exact
                        isActive={this.NavActive}
                        onClick={this.OpenMenu}
                      >
                        <Chevron className="Nav__icon" />
                        {item.heading}
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </header>
        <SearchBar open={SearchOpen} onToggleOpen={this.toggleSearch} />
        <GuestServices
          OpenMenu={this.OpenMenu}
          isOpen={GuestServicesOpen}
          toggleGuestServices={this.toggleGuestServices}
        />
        <Today
          isOpen={TodayOpen}
          toggleToday={this.toggleToday}
          OpenMenu={this.OpenMenu}
        />
        <Directory
          OpenMenu={this.OpenMenu}
          isOpen={DirectoryOpen}
          toggleDirectory={this.toggleDirectory}
        />

        <div style={{ height: `${headerHeight}px`, display: "block" }} />
      </React.Fragment>
    );
  }
}

GlobalHeader.propTypes = {
  NavigationItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

export default GlobalHeader;
