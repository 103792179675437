import PREVIEW_QUERY from "./Preview";

const CARD_QUERY = (onName) => {
  return `
    fragment Card${onName} on ${onName} {
      ...Preview${onName}
    }
    ${PREVIEW_QUERY(onName)}
  `;
};

export default CARD_QUERY;
