import { ApolloClient, gql, HttpLink, ApolloLink } from "apollo-boost";
// import { Query } from 'react-apollo';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragments.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const dataIdFromObject = (object) => {
  if (object.preview) {
    return `card-${defaultDataIdFromObject(object)}`;
  }
  switch (object.__typename) {
    case "Section":
      return `${object.__typename}-${Math.ceil(Math.random() * 1000)}`;
    default:
      return defaultDataIdFromObject(object); // fall back to default handling
  }
};

const cache = new InMemoryCache({ dataIdFromObject, fragmentMatcher });

const httpLink = new HttpLink({
  uri: process.env.API_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: `Bearer ${process.env.GRAPHQL_TOKEN}`,
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const searchAuthLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: `Bearer ${process.env.SEARCH_GRAPHQL_TOKEN}`,
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  queryDeduplication: false,
});

export const searchClient = new ApolloClient({
  cache,
  link: searchAuthLink.concat(httpLink),
  queryDeduplication: false,
});

class API {
  queryData = (query) => {
    return client.query({
      query: gql(query),
    });
  };
  cancellableQueryData = (query, abortController) => {
    return searchClient.query({
      query: gql(query),
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });
  };
}

export default API;
