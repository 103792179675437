import BUTTON_QUERY from "../SubLevel/Button";
import GRADIENT_QUERY from "../SubLevel/Gradient";

/**
 * GraphQL query fragments for getting the content picker data
 *
 * @param {string} onName The name of entry section to query
 */
const CONTENTPICKER_QUERY = (onName) => {
  return `
    fragment ContentPicker on ${onName} {
      contentPicker {
        __typename

        ...on contentPicker_buttonBlock_BlockType {
          id
          ...ButtoncontentPicker_buttonBlock_BlockType
          fullWidth
          identifier
        }

        ...on contentPicker_dualContent_BlockType {
          id
          lImages {
            id
            url(transform:"globalHalf")
            title
          }
          lHeading
          lBody
          lButtonText
          lButtonEntry {
            id
            uri
          }
          lButtonUrl
          lButtonOpenInNewWindow
          rImages {
            id
            url(transform:"globalHalf")
            title
          }
          rHeading
          rBody
          rButtonText
          rButtonEntry {
            id
            uri
          }
          rButtonUrl
          rButtonOpenInNewWindow
          identifier
        }

        ...on contentPicker_genericContent_BlockType {
          id
          heading
          body
          identifier
        }

        ...on contentPicker_globalBlocks_BlockType {
          id
          entries {
            ...on globalHalfImageText_globalHalfImageText_Entry {
              globalHalfImageText {
                ...on globalHalfImageText_imageTextContent_BlockType {
                  heading
                  images {
                    id
                    url(transform:"globalHalf")
                    title
                  }
                  leftRight
                  body
                  ...ButtonglobalHalfImageText_imageTextContent_BlockType
                  backgroundImage {
                    id
                    url
                    title
                  }
                }
              }
            }
          }
          identifier
        }

        ...on contentPicker_halfImageText_BlockType {
          id
          heading
          images {
            id
          	url(transform:"globalHalf")
            title
          }
          leftRight
          body
          ...ButtoncontentPicker_halfImageText_BlockType
          ...GradientcontentPicker_halfImageText_BlockType
          backgroundImage {
            url
            title
          }
          identifier
        }

        ...on contentPicker_iconList_BlockType {
          id
          listItems {
            ...on listItems_BlockType {
              item
              icon {
                id
                url(transform:"globalHalf")
                title
              }
            }
          }
          identifier
        }

        ...on contentPicker_imageSlider_BlockType {
          id
					images {
            id
            url(transform:"imageSlider")
            title
          }
          identifier
        }

      	...on contentPicker_linkBlock_BlockType {
          id
          links {
            ...on links_BlockType {
              id
              itemHeading
              itemEntry {
                id
                uri
              }
              itemUrl
              itemAsset {
                id
                url
                title
              }
              itemOpenInNewWindow
            }
          }
          identifier
        }

        ...on contentPicker_relatedPages_BlockType {
          id
          display
          heading
          ...GradientcontentPicker_relatedPages_BlockType
          identifier
        }

        ...on contentPicker_accordionContent_BlockType {
          id
          items {
            ...on items_BlockType {
              id
              heading
              body
            }
          }
          identifier
        }

        ...on contentPicker_html_BlockType {
          id
          body
          identifier
        }
      }
    }

    ${BUTTON_QUERY("globalHalfImageText_imageTextContent_BlockType")}
    ${BUTTON_QUERY("contentPicker_buttonBlock_BlockType")}
    ${GRADIENT_QUERY("contentPicker_halfImageText_BlockType")}
    ${BUTTON_QUERY("contentPicker_halfImageText_BlockType")}
    ${GRADIENT_QUERY("contentPicker_relatedPages_BlockType")}
  `;
};

export default CONTENTPICKER_QUERY;
