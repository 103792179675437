/**
 * GraphQL query fragments for getting the mainstage banner data
 *
 * @param {string} onName The name of entry section to query
 */
const MAINSTAGEBANNER_QUERY = (onName) => {
  return `
    fragment MainStageBanner on ${onName} {
      mainStageBanner {
        ... on mainStageBanner_bannerContent_BlockType {
          overlay {
            title
            url
          }
          overlayText
          images {
            title
            url
          }
          buttonText
          buttonUrl
          buttonOpenInNewWindow
        }
      }
    }
  `;
};

export default MAINSTAGEBANNER_QUERY;
