/**
 * GraphQL query fragments for getting the cinema intro data
 *
 * @param {string} onName The name of entry section to query
 */
const CINEMAINTRO_QUERY = (onName) => {
  return `
    fragment CinemaIntroduction on ${onName} {
      cinemaIntroduction
    }
  `;
};

export default CINEMAINTRO_QUERY;
