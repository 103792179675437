import cx from "classnames";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-orange.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close-icon-black.svg";
import Helpers from "../../../lib/HelperUtils";
import store from "../../../Stores/GuestServicesStore";
import Button from "../../Elements/Button";
import Loader from "../../Elements/Loader";
import Carousel from "../../Utilities/Carousel";
import styles from "./GuestServices.module.scss";

@observer
class GuestServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
    };
  }

  onRedirect = () => {
    const { OpenMenu } = this.props;
    OpenMenu();
  };

  componentDidMount = () => {
    this.getContent();
  };

  componentDidUpdate = () => {
    this.getContent();
  };

  getContent = () => {
    const { isOpen } = this.props;
    const { requestSent } = this.state;

    if (!requestSent && isOpen) {
      const { fetchContent } = store;
      fetchContent(
        "guestServicesHomepage",
        "guestServicesHomepage_guestServicesHomepage_Entry"
      );
      this.updateRequestStatus();
    }
  };

  updateRequestStatus = () => {
    this.setState({
      requestSent: true,
    });
  };

  render() {
    const { isOpen, toggleGuestServices } = this.props;
    const { Content, status } = store;

    const button =
      typeof toggleGuestServices === "function" ? (
        <button
          type="button"
          onClick={toggleGuestServices}
          className={styles.GuestServices__back}
        >
          <div className={styles.GuestServices__back_mobile}>
            <Chevron />
            <span>Menu</span>
          </div>
          <div className={styles.GuestServices__back_desktop}>
            <Close />
          </div>
        </button>
      ) : (
        <a href="/">
          <button type="button" className={styles.GuestServices__back}>
            <div className={styles.GuestServices__back_mobile}>
              <Chevron />
              <span>Menu</span>
            </div>
            <div className={styles.GuestServices__back_desktop}>
              <Close />
            </div>
          </button>
        </a>
      );

    if (status !== "done" || typeof Content === "undefined") {
      return (
        <div className={cx(styles.GuestServices, { [styles.active]: isOpen })}>
          {button}
          <div className={styles.GuestServices__Content}>
            <Loader />
          </div>
        </div>
      );
    }

    const {
      guestServicesContent,
      relatedField1Title,
      relatedField1,
      relatedField2Title,
      relatedField2,
    } = Content;

    const {
      heading,
      body,
      telephone,
      buttonUrl,
      buttonText,
      buttonOpenInNewWindow,
      linkTitle,
      linkText,
      linkUrl,
      linkImage,
      linkOpenInNewWindow,
    } = guestServicesContent[0];

    return (
      <div
        className={cx(styles.GuestServices, {
          [styles.active]: isOpen,
          [styles.inline]: typeof toggleGuestServices !== "function",
        })}
      >
        {linkImage}

        <div className={styles.GuestServices__Content}>
          <div className={styles.GuestServices__Description}>
            <h2 className={styles.GuestServices__Description_Title}>
              <span>{heading}</span>
            </h2>
            <p className={styles.GuestServices__Description_Content}>
              {ReactHtmlParser(body)}
            </p>
            <Button
              onClick={this.onRedirect}
              label={buttonText}
              url={buttonUrl}
              rounded
              buttonOpenInNewWindow={buttonOpenInNewWindow}
            />
            <span className={styles.GuestServices__Description_Phone}>
              <b>Telephone: </b>
              {telephone}
            </span>

            <div className={styles.GuestServices__Description_TrainedBy}>
              {(linkTitle !== null && linkUrl !== null && linkText !== null) && (
                <span>
                  <span>{linkTitle}</span>
                  <a
                    href={linkUrl}
                    {...(linkOpenInNewWindow
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : null)}
                  >
                    {linkText}
                  </a>
                </span>
              )}
              {linkImage.length !== 0 && (
                <img
                  src={Helpers.transformUrlCheck(linkImage[0].url)}
                  alt={linkImage[0].title}
                />
              )}
            </div>
          </div>

          <div className={styles.GuestServices__Carousel__Wrap}>
            <div className={styles.GuestServices__Carousel__Single}>
              <Carousel
                Title={relatedField1Title}
                Content={relatedField1}
                blueBg
                itemClicked={this.onRedirect}
                perViewDefault="1"
              />
            </div>

            <div className={styles.GuestServices__Carousel__Single}>
              <Carousel
                Title={relatedField2Title}
                Content={relatedField2}
                blueBg
                itemClicked={this.onRedirect}
                perViewDefault="1"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GuestServices.defaultProps = {
  toggleGuestServices: null,
  OpenMenu: null,
};

GuestServices.propTypes = {
  OpenMenu: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  toggleGuestServices: PropTypes.func,
};

export default GuestServices;
