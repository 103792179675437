import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./Button.module.scss";

const Button = (props) => {
  const {
    isLink,
    label,
    id,
    url,
    priority,
    rounded,
    color,
    onClick,
    children,
    classes,
    isFull,
    disabled,
    onMouseEnter,
    bookingStyleButton,
    mailTo,
    entry,
    openInNewWindow,
    underline,
    bookingWidgetStyleButton,
  } = props;

  const getButtonClasses = (btnPriority, btnRounded, btnColor, btnIsFull) =>
    cx(
      classes,
      styles.Button,
      { [styles[`Button__${btnPriority}`]]: btnPriority },
      { [styles[`Button__${btnPriority}_rounded`]]: btnRounded },
      { [styles[`Button__${btnPriority}_${btnColor}`]]: btnColor },
      { [styles.Button__Fullwidth]: btnIsFull },
      {
        [styles[
          `booking_widget_style${underline ? "_selected" : ""}`
        ]]: bookingWidgetStyleButton,
      },
      { [styles.booking_style]: bookingStyleButton }
    );
  let emailLink = false;
  if (mailTo !== null) {
    emailLink = mailTo.indexOf("@") > 0 ? `mailto:${mailTo}` : false;
  }

  return emailLink ? (
    <a
      href={emailLink}
      className={getButtonClasses(priority, rounded, color, isFull)}
      data-disabled={disabled}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
      {children}
    </a>
  ) : isLink ? (
    entry ? (
      <Link
        id={id}
        to={`/${entry}`}
        className={getButtonClasses(priority, rounded, color, isFull)}
        disabled={disabled}
        onClick={onClick}
        {...(openInNewWindow
          ? { target: "_blank", rel: "noopener noreferrer" }
          : null)}
      >
        {label}
        {children}
      </Link>
    ) : (
      <a
        id={id}
        href={url}
        className={getButtonClasses(priority, rounded, color, isFull)}
        data-disabled={disabled}
        {...(openInNewWindow
          ? { target: "_blank", rel: "noopener noreferrer" }
          : null)}
      >
        {label}
        {children}
      </a>
    )
  ) : (
    <button
      id={id}
      className={getButtonClasses(priority, rounded, color, isFull)}
      type="button"
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnter || undefined}
    >
      {label}
      {children}
    </button>
  );
};

Button.propTypes = {
  isLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  priority: PropTypes.oneOf(["primary", "secondary"]),
  rounded: PropTypes.bool,
  color: PropTypes.oneOf([false, "blue", "grey", "orange", "darkgrey"]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.string,
  isFull: PropTypes.bool,
  disabled: PropTypes.bool,
  onMouseEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  bookingStyleButton: PropTypes.bool,
  mailTo: PropTypes.string,
  entry: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  openInNewWindow: PropTypes.bool,
  underline: PropTypes.bool,
  bookingWidgetStyleButton: PropTypes.bool,
};

Button.defaultProps = {
  isLink: true,
  id: "",
  url: "",
  priority: "primary",
  rounded: true,
  color: false,
  onClick: false,
  children: "",
  classes: "",
  isFull: false,
  disabled: false,
  onMouseEnter: false,
  bookingStyleButton: false,
  mailTo: null,
  entry: false,
  openInNewWindow: false,
  underline: false,
  bookingWidgetStyleButton: false,
};

export default Button;
