import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-orange.svg";
import { ReactComponent as Instagram } from "../../../Assets/icons/instagram-icon.svg";
import { ReactComponent as Facebook } from "../../../Assets/icons/social-facebook-icon-orange.svg";
import { ReactComponent as Twitter } from "../../../Assets/icons/social-twitter-icon-orange.svg";
import logo from "../../../Assets/RWB_red_logo.png";
import Helpers from "../../../lib/HelperUtils";
import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Get the url for the navigation item
   *
   * @param {object} item The current navigation item object
   */
  getItemUrl = (item) => {
    const itemUrl = {
      uri: "not-found",
      openInNewTab: item.openInNewTab,
    };

    // Get either the external link or the entry uri
    if (item.buttonEntry.length !== 0 || item.externalLink !== "") {
      // Have entry url
      itemUrl.uri = item.buttonEntry.length !== 0 && item.buttonEntry[0].uri;

      // Have external link - Override entry url
      if (item.externalLink !== null) {
        itemUrl.uri = item.externalLink;
      }

      // Open in new tab
      if (item.openInNewTab) {
        itemUrl.openInNewTab = true;
      }

      // If homepage set url to empty string for routing
      if (itemUrl.uri === "homepage") {
        itemUrl.uri = "";
      }
    }

    return itemUrl;
  };

  render() {
    const { NavigationItems, SocialLinks } = this.props;
    return (
      <footer className="Footer">
        <div className="Footer__top">
          <img
            src={Helpers.transformUrlCheck(logo)}
            alt="logo"
            className="Logo"
          />
          {SocialLinks.length > 0 && (
            <div className="Footer__top_social">
              {SocialLinks[0].twitter !== "" && (
                <a
                  href={SocialLinks[0].twitter}
                  className="Footer__top_social_twitter"
                >
                  <Twitter />
                </a>
              )}

              {SocialLinks[0].facebook !== "" && (
                <a
                  href={SocialLinks[0].facebook}
                  className="Footer__top_social_facebook"
                >
                  <Facebook />
                </a>
              )}

              {SocialLinks[0].instagram !== "" && (
                <a
                  href={SocialLinks[0].instagram}
                  className="Footer__top_social_instagram"
                >
                  <Instagram />
                </a>
              )}
            </div>
          )}
        </div>
        <div className="Footer__NavOuter">
          <ul className="Footer__Nav">
            {NavigationItems.map((item) => {
              // Get the urls for this item
              const itemUrl = this.getItemUrl(item);

              // return the item
              return (
                <li key={item.id} className="Footer__Nav_item">
                  {itemUrl.openInNewTab ? (
                    <a
                      className="Footer__Nav__link"
                      href={`${itemUrl.uri}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      exact="true"
                    >
                      <Chevron className="Footer__Nav__icon" />
                      <span>{item.heading}</span>
                    </a>
                  ) : (
                    <NavLink
                      className="Footer__Nav__link"
                      to={`/${itemUrl.uri}`}
                      exact
                    >
                      <Chevron className="Footer__Nav__icon" />
                      <span>{item.heading}</span>
                    </NavLink>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  NavigationItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  SocialLinks: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

export default Footer;
