import moment from "moment";

class Helpers {
  objectToQuery = (object) => {
    return Object.keys(object)
      .map((key) => `${key}=${object[key]}`)
      .join("&");
  };

  /**
   * Create a clean object with no empty properties
   *
   * @param {object} object The object to clean
   * @param {object} mergeObject An object to merge the clean object into
   */
  getCleanObject = (object, mergeObject = {}) => {
    const cleanObject = mergeObject;

    Object.keys(object).forEach((item) => {
      if (object[item]) {
        cleanObject[item] = object[item];
      }
    });

    return cleanObject;
  };

  deCamelCase = (str) => {
    if (str) {
      str = str.replace(/([A-Z])/g, " $1");
      str = str.charAt(0).toUpperCase() + str.slice(1);
    }
    switch (str) {
      case "Eat Drink":
      case "Beauty Wellness":
      case "Hotel Spa":
        str = str.split(" ").join(" & ");
        break;
    }
    str.replace("And", "and");
    return str;
  };

  transformUrlCheck = (str) => {
    //check if the url is a craft image transform url
    if (str !== null && str.indexOf("assets/generate-transform") > -1) {
      // remove the front end domain
      let oldString = str.substring(str.indexOf("index.php") + 9, str.length);
      //Get api up and remove the trailing api/gapi
      let dataUrl = process.env.API_URL;
      dataUrl = dataUrl.substring(0, dataUrl.lastIndexOf("/"));
      //Concatenate the 2 string
      return dataUrl + oldString;
    } else {
      return str;
    }
  };

  /**
   * Create a sentence out of array of selected days + dates
   *
   * @param {array} dayArr days of the week selected
   *  @param {date} startDate the chosen start date
   *  @param {date} endDate the chosen end date
   */
  createSelectedDaySentence = (dayArr, startDate, endDate) => {
    const formattedStartDate = moment(startDate).format("dddd Do MMMM YYYY");
    const formattedendDate =
      endDate && moment(endDate).format("dddd Do MMMM YYYY");

    const betweenDates = (isBeginningOfSentence) => {
      return `${
        isBeginningOfSentence ? "B" : "b"
      }etween ${formattedStartDate} and ${formattedendDate} at`;
    };

    const listDays = () => {
      // given 2 or less days will make a simple 'and' list
      if (dayArr.length <= 2) {
        return dayArr.join(" and ");
      } else {
        // given 3+ days will create a list with commas + and
        const csvDays = dayArr.join(", ");
        const lastCommaPos = csvDays.lastIndexOf(",");
        const listWithAndAtEnd =
          csvDays.substring(0, lastCommaPos + 1) +
          " and" +
          csvDays.substring(lastCommaPos + 1);
        return listWithAndAtEnd;
      }
    };

    if (!endDate) {
      return `${formattedStartDate} at`;
    }

    if (dayArr.includes("Every day")) {
      // will  not list individual days of the week
      return betweenDates(true);
    } else {
      // will list selected days of the week
      return `Every ${listDays()} ${betweenDates(false)}`;
    }
  };

  formatCurrency = (n) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    });
    return formatter.format(n);
  };
}

const helpers = new Helpers();

export default helpers;
