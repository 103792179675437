/* Top Level Queries */
import CAROUSEL_QUERY_FRAGMENTS from "./API/Fragments/SubLevel/CarouselFragments";
import ACTIONBAND_QUERY from "./API/Fragments/TopLevel/ActionBand";
import CARDPAGES_QUERY from "./API/Fragments/TopLevel/CardPages";
import CAROUSELSECTION_QUERY from "./API/Fragments/TopLevel/CarouselSection";
import CINEMAINTRO_QUERY from "./API/Fragments/TopLevel/CinemaIntro";
import CONTENTPICKER_QUERY from "./API/Fragments/TopLevel/ContentPicker";
import DEFAULTOPENINGHOURS_QUERY from "./API/Fragments/TopLevel/DefaultOpeningHours";
import GETTINGHERE_QUERY from "./API/Fragments/TopLevel/GettingHere";
import GLOBALBLOCKS_QUERY from "./API/Fragments/TopLevel/GlobalBlocks";
import INTRODUCTION_QUERY from "./API/Fragments/TopLevel/Introduction";
import MAINSTAGEBANNER_QUERY from "./API/Fragments/TopLevel/MainStageBanner";
import TRAVELPAGES_QUERY from "./API/Fragments/TopLevel/TravelPages";

class QueryUtils {
  /**
   * GraphQL query fragments for getting the mainstage banner data
   *
   * @param {string} onName The name of entry section to query
   */
  mainStageBannerQuery = (onName) => {
    return MAINSTAGEBANNER_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the mainstage banner data
   *
   * @param {string} onName The name of entry section to query
   */
  introductionQuery = (onName) => {
    return INTRODUCTION_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the carousel data
   *
   * @param {string} onName The name of entry section to query
   * @param {string} partName The name of the section part to query
   */
  carouselSectionQuery = (onName, partName) => {
    return CAROUSELSECTION_QUERY(onName, partName);
  };

  carouselSectionQueryFragments = () => {
    return CAROUSEL_QUERY_FRAGMENTS;
  };

  /**
   * GraphQL query fragments for getting the cinema introduction data
   */
  cinemaIntroQuery = (onName) => {
    return CINEMAINTRO_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the getting here data
   *
   * @param {string} onName The name of entry section to query
   */
  gettingHereQuery = (onName) => {
    return GETTINGHERE_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the travel pages data
   *
   * @param {string} onName The name of entry section to query
   */
  travelPageQuery = (onName) => {
    return TRAVELPAGES_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the global blocks data
   *
   * @param {string} onName The name of entry section to query
   */
  globalBlocksQuery = (onName) => {
    return GLOBALBLOCKS_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the content picker data
   *
   * @param {string} onName The name of entry section to query
   */
  contentPickerQuery = (onName) => {
    return CONTENTPICKER_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the default opening hours data
   *
   * @param {string} onName The name of entry section to query
   */
  defaultOpeningHoursQuery = () => {
    return DEFAULTOPENINGHOURS_QUERY();
  };

  /**
   * GraphQL query fragments for getting the default opening hours data
   *
   * @param {string} onName The name of entry section to query
   */
  cardPagesQuery = (onName) => {
    return CARDPAGES_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the default opening hours data
   *
   * @param {string} onName The name of entry section to query
   */
  actionBandQuery = (onName) => {
    return ACTIONBAND_QUERY(onName);
  };

  /**
   * GraphQL query fragments for getting the realted pages data
   *
   * @param {string} relatedTypes The name of entry section to query
   */
  relatedPagesQuery = (relatedTypes, queryName, queryArgs, onName) => {
    // Check for related pages
    // Run a query if we have any
    const uniqueRelatedTypes = [].concat(
      [],
      relatedTypes.map((item) => item.display)
    );

    if (uniqueRelatedTypes.length == 0) {
      return null;
    }

    const relatedSections = [
      { sectionType: "careersHomepage_careersHomepage_Entry" },
      { sectionType: "careers_careers_Entry" },
      { sectionType: "casino_casino_Entry" },
      { sectionType: "competitions_competitions_Entry" },
      { sectionType: "eatDrink_eatDrink_Entry" },
      { sectionType: "entertainment_entertainment_Entry" },
      { sectionType: "events_events_Entry" },
      { sectionType: "experiences_experiences_Entry" },
      { sectionType: "faqs_faqs_Entry" },
      { sectionType: "genericPages_genericPages_Entry" },
      { sectionType: "gettingHere_gettingHere_Entry" },
      { sectionType: "guestServices_guestServices_Entry" },
      { sectionType: "news_news_Entry" },
      { sectionType: "offers_offers_Entry" },
      { sectionType: "offersHomepage_offersHomepage_Entry" },
      { sectionType: "privateEventsHomepage_privateEventsHomepage_Entry" },
      { sectionType: "shops_shop_Entry" },
      { sectionType: "spa_spa_Entry" },
      {
        sectionType:
          "competitionsNewsEventsHomepage_competitionsNewsEventsHomepage_Entry",
      },
      { sectionType: "homepage_homepage_Entry" },
      { sectionType: "shopHomepage_shopHomepage_Entry" },
      { sectionType: "spaHomepage_spaHomepage_Entry" },
      { sectionType: "eatDrinkHomepage_eatDrinkHomepage_Entry" },
      { sectionType: "entertainmentHomepage_entertainmentHomepage_Entry" },
      { sectionType: "hotelHomepage_hotelHomepage_Entry" },
      { sectionType: "casinoHomepage_casinoHomepage_Entry" },
      { sectionType: "cinemaHomepage_cinemaHomepage_Entry" },
      { sectionType: "experiencesHomepage_experiencesHomepage_Entry" },
      { sectionType: "contactUs_contactUs_Entry" },
    ];

    let relatedSectionsQuery = relatedSections.map(
      (item) =>
        `...on ${item.sectionType} {
          id
          preview {
            ...on preview_preview_BlockType {
              id
              shortDescription
              image {
                id
                url(transform:"previewTile")
                title
              }
              previewTitle
              tag
              showTag
            }
          }
        }`
    );

    relatedSectionsQuery = relatedSectionsQuery.join(" ");

    // Remove dash and/or forward slash from query name
    queryName = queryName.replace(/-|\//gi, "");

    let relatedQuery = `
    {
      ${queryName}Related: entries(${queryArgs}) {
        ...on ${onName} {
    `;

    uniqueRelatedTypes.map((pageType) => {
      if (pageType === "crossSell") {
        return (relatedQuery += `
        crossSell {
          id
          title
          uri
          sectionHandle
          ${relatedSectionsQuery}
        }
        `);
      }

      if (pageType === "offers") {
        return (relatedQuery += `
        offers {
          id
          title
          uri
          sectionHandle
          ${relatedSectionsQuery}
        }
        `);
      }

      if (pageType === "events") {
        return (relatedQuery += `
        events {
          id
          title
          uri
          sectionHandle
          ${relatedSectionsQuery}
        }
        `);
      }
    });

    relatedQuery += `
        }
      }
    }`;
    return relatedQuery;
  };

  /**
   * Gets the toggle hours query if section type exists in the array
   *
   * @param {string} onName The name of entry section to query
   */
  getToggleHours = (onName) => {
    const toggleHoursTypeNames = [
      "eatDrinkHomepage_eatDrinkHomepage_Entry",
      "eatDrink_eatDrink_Entry",
      "entertainmentHomepage_entertainmentHomepage_Entry",
      "entertainment_entertainment_Entry",
      "spaHomepage_spaHomepage_Entry",
      "shopHomepage_shopHomepage_Entry",
      "shops_shop_Entry",
    ];

    const toggleHours = toggleHoursTypeNames.includes(onName)
      ? "toggleHours"
      : "";

    return toggleHours;
  };

  popoutFields = () => {
    return `popout {
      id
      ... on popOutCallToActions_popOutCallToActions_Entry {
        popOutForm
        popOutLabel
        popOutLink
      }
    }`;
  };

  sectionSpecificFields = (onName) => {
    let queryText = "";

    switch (onName) {
      case "experiences_experiences_Entry":{
        queryText = `showPhoneNumber
      phoneNumber 
      `;
        break;
      }
      case "eatDrink_eatDrink_Entry":
      case "entertainment_entertainment_Entry":
      case "shops_shop_Entry": {
        queryText = `showPhoneNumber
      phoneNumber
      customMap {
        url
      }
      `;
        break;
      }
      case "faqs_faqs_Entry": {
        queryText = `faqs {
        __typename
        ...on faqs_faqHeading_BlockType {
          id
          heading
        }
        ...on faqs_faq_BlockType {
          id
          question
	        answer
          identifier
        }
      }`;
        break;
      }
      case "guestServicesHomepage_guestServicesHomepage_Entry": {
        queryText = `guestServicesContent {
        ...on guestServicesContent_guestServicesContent_BlockType {
          id
          heading
          body
          telephone
          buttonUrl
          buttonText
          buttonOpenInNewWindow
          linkTitle
          linkUrl
          linkText
          linkImage {
            id
            url
            title
          }
          linkOpenInNewWindow
        }
      }`;
        break;
      }
    }

    return queryText;
  };

  seoFields = () => {
    return `seomatic: seomatic(uri:"${location.pathname}") {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
    }`;
  };
  schemaMarkupFields = () => {
    return `schemaMarkup {
      value
    }`;
  };
}

const queryUtils = new QueryUtils();

export default queryUtils;
