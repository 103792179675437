import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import OpeningTimesWeek from "../../Elements/OpeningTimesWeek";
import styles from "./OpeningTimesSingleTab.module.scss";

const OpeningTimesSingleTab = (props) => {
  const { url, Text, section, mode, isFull, Block, subPage } = props;
  return (
    <div
      className={cx(
        styles.OpeningTimesSingleTab,
        mode === "alt" ? styles.OpeningTimesSingleTabAlt : null,
        { [styles.full]: isFull },
        { [styles.block]: Block }
      )}
    >
      <OpeningTimesWeek
        url={url}
        Text={Text}
        mode="day"
        section={section}
        subPage={subPage}
      />
    </div>
  );
};

OpeningTimesSingleTab.propTypes = {
  isFull: PropTypes.bool,
  url: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  Text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mode: PropTypes.string,
  Block: PropTypes.bool,
  subPage: PropTypes.bool,
};

OpeningTimesSingleTab.defaultProps = {
  Text: "",
  mode: "",
  isFull: false,
  Block: false,
  subPage: false,
};

export default OpeningTimesSingleTab;
