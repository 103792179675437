import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Chevron } from "../../Assets/icons/chevron-grey.svg";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="slick-arrow slick-prev">
      <Chevron />
    </button>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  onClick: () => {},
};

export default PrevArrow;
