const PREVIEW_QUERY = (onName) => {
  const transform = onName == "GettingHere" ? "gettingHere" : "previewTile";
  return `
    fragment Preview${onName} on ${onName} {
      preview {
        ...on preview_preview_BlockType {
          id
          shortDescription
          image {
            id
            url(transform:"${transform}")
            title
          }
          previewTitle
          tag
          showTag
        }
      }
    }
  `;
};

export default PREVIEW_QUERY;
