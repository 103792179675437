import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
// import config from '../../Config';
// import { extendMoment } from 'moment-range';
import API from "../../../lib/API/ElementsAPI";
import styles from "./OpeningTimesWeek.module.scss";
import Loader from "../Loader";

/**
 * OpeningTimesWeek
 */
class OpeningTimesWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dayTimes: "",
      weekdays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      // startDayNumber: 1
    };
  }

  componentDidMount() {
    const { url } = this.props;
    const api = new API();

    // Request data from API
    api
      .requestGet(url)
      .then((response) => {
        // Get opening times from response
        const {
          openingHours,
          openingExceptions,
          holidayDates,
          holidayOpeningHours,
          sectionHolidayDates,
          sectionHolidayOpeningHours,
        } = response;

        this.setStateFromData({
          openingHours,
          openingExceptions,
          holidayDates,
          holidayOpeningHours,
          sectionHolidayDates,
          sectionHolidayOpeningHours,
        });
      })
      .catch((error) => {
        error;
        this.setState({
          error: true,
        });
      });
  }

  setStateFromData = ({
    openingHours,
    openingExceptions,
    holidayDates,
    holidayOpeningHours,
    sectionHolidayDates,
    sectionHolidayOpeningHours,
  }) => {
    // Get todays times
    const dayTimes = this.getDaysHours({
      openingHours,
      openingExceptions,
      holidayDates,
      holidayOpeningHours,
      sectionHolidayDates,
      sectionHolidayOpeningHours,
    });

    // Set todays times to state
    this.setState({
      dayTimes,
    });
  };

  getExceptions = (openingExceptions, day) => {
    let exception = false;
    const currentDate = new Date(day);

    if (!_.isEmpty(openingExceptions)) {
      openingExceptions.forEach((exp) => {
        if (!_.isEmpty(exp.date)) {
          const expDate = new Date(exp.date.replace(/\s/g, "-"));
          if (expDate.getTime() === currentDate.getTime()) {
            exception = exp;
          }
        }
      });
    }
    return exception;
  };

  getHolidays = (sectionDates, holidayDates, day) => {
    const holiday = {
      isHoliday: false,
      type: "",
    };
    const currentDate = new Date(day);

    // Have we got section dates?
    if (sectionDates !== undefined) {
      if (!_.isEmpty(sectionDates)) {
        sectionDates.forEach((Holiday) => {
          if (!_.isEmpty(Holiday.startDate) && !_.isEmpty(Holiday.endDate)) {
            const startDate = new Date(Holiday.startDate.replace(/\s/g, "-"));
            const endDate = new Date(Holiday.endDate.replace(/\s/g, "-"));
            if (
              startDate.getTime() <= currentDate.getTime() &&
              endDate.getTime() >= currentDate.getTime()
            ) {
              holiday.isHoliday = true;
              holiday.type = "section";
            }
          }
        });
      }
    }

    // Individual date overrides site section date (e.g. shop)
    if (!_.isEmpty(holidayDates)) {
      holidayDates.forEach((Holiday) => {
        if (!_.isEmpty(Holiday.startDate) && !_.isEmpty(Holiday.endDate)) {
          const startDate = new Date(Holiday.startDate.replace(/\s/g, "-"));
          const endDate = new Date(Holiday.endDate.replace(/\s/g, "-"));
          if (
            startDate.getTime() <= currentDate.getTime() &&
            endDate.getTime() >= currentDate.getTime()
          ) {
            holiday.isHoliday = true;
            holiday.type = "outlet";
          }
        }
      });
    }
    return holiday;
  };

  /**
   * Get the opening times for the current day
   *
   * @param {object} openingHours The standard opening times
   * @param {object} openingExceptions The opening times exceptions
   * @param {object} holidayDates The holiday dates
   * @param {object} holidayOpeningHours The holiday opening times
   */
  getDaysHours = ({
    openingHours,
    openingExceptions,
    holidayDates,
    holidayOpeningHours,
    sectionHolidayDates,
    sectionHolidayOpeningHours,
  }) => {
    const { mode } = this.props;
    const nextSevenDays = [];
    const outputArr = [];

    // Build the week of dates with the current date as the start date
    for (let i = 0; i <= 6; i++) {
      const momentObj = moment().add(i, 'day');
      const date = momentObj.format('YYYY-MM-DD'); // e.g. 2022-07-29
      const dayIndex = parseInt( momentObj.format('E') ) - 1; // index 0 to 6
      const dayName = momentObj.format('dddd'); // e.g. Monday

      nextSevenDays[dayIndex] = { dayIndex, dayName, date }; // next 7 days including today, e.g. [{dayIndex: 4, dayName: 'Friday', date: '2022-07-29'}, ...]
    }

    // Loop over the next 7 days and get the opening times
    nextSevenDays.forEach((obj) => {
      const { dayIndex, date, dayName } = obj;

      // Set up opening/closing strings
      let openingTime = "";
      let closingTime = "";

      const exception = this.getExceptions(openingExceptions, date);
      const holiday = this.getHolidays(sectionHolidayDates, holidayDates, date);
      const todaysTimes = Object.values(openingHours)[dayIndex];
      const holidayTimes = Object.values(holidayOpeningHours)[dayIndex];
      const sectionHolidayTimes =
        sectionHolidayOpeningHours !== undefined
          ? Object.values(sectionHolidayOpeningHours)[dayIndex]
          : null;

      let open;
      let close;

      if (exception) {
        open = exception.open;
        close = exception.close;
      } else if (holiday.isHoliday) {
        if (holiday.type === "section") {
          open = sectionHolidayTimes.open;
          close = sectionHolidayTimes.close;
        } else {
          open = holidayTimes.open;
          close = holidayTimes.close;
        }
      } else {
        open = todaysTimes.open;
        close = todaysTimes.close;
      }

      openingTime = open === null ? false : open; // set today's opening time
      closingTime = close === null ? false : close; // set today's closing time

      if (mode === "week") {
        // Day of the week opening time
        outputArr.push(
          <div style={{display:"flex", justifyContent: "space-between"}} key={`day${dayIndex}`}>
            <strong style={{paddingRight:'5px'}}>
              {dayName}
            </strong>
            <div style={{paddingRight:'10px'}}>
              {this.getDayTimesString(openingTime, closingTime)}
            </div>
          </div>
        );
      } else {
        // Single day opening time
        outputArr.push(this.getTodayTimesString(openingTime, closingTime));
      }
    });

    return outputArr;
  };

  /**
   * Gets the string to be displayed as the opening times
   *
   * @param {string} open The opening time
   * @param {string} close The closing time
   */
  getTodayTimesString(open, close) {
    if (open && !close) {
      return `Opens ${open}`; // Display just opening time
    }
    if (!open && close) {
      return `Closes ${close}`; // Display just closing time
    }
    if (open && close) {
      const openMatch12 = open.match(/^(\d{1,2}):(\d{1,2})(\D{2})$/);
      const openMatch24 = open.match(/^(\d{1,2}):(\d{1,2})$/);
      if (openMatch12 || openMatch24) {
        const now = new Date();
        let hour = "";
        let minutes = "";
        if (openMatch24) {
          hour = openMatch24[1];
          minutes = openMatch24[2];
        }
        if (openMatch12) {
          hour =
            openMatch12[3].toLowerCase() === "pm"
              ? parseInt(openMatch12[1]) + 12
              : openMatch12[1];
          minutes = openMatch12[2];
        }

        if (now.getHours() > hour && now.getMinutes() > minutes) {
          return `Open Today until ${close}`;
        }
      }
      return `${open} to ${close}`; // Display opening and closing times
    }
    if (!open && !close) {
      return "Closed "; // Display closed
    }
    return "Closed "; // Display closed
  }

  getDayTimesString(open, close) {
    if (open && !close) {
      return `Opens at ${open}`; // Display just opening time
    }
    if (!open && close) {
      return `Open Until ${close}`; // Display just closing time
    }
    if (open && close) {
      return `Open ${open} - ${close}`; // Display opening and closing times
    }
    if ((open === "closed") & (close === "closed")) {
      return "Closed ";
    }
    return "Closed ";
  }

  render() {
    const { dayTimes, error, weekdays } = this.state;
    const { mode, Text } = this.props;

    if (mode === "day") {
      const currentDay = new Date();
      const dt = moment(currentDay, "YYYY-MM-DD").format("dddd");
      const getDay = weekdays.indexOf(dt);

      return !_.isEmpty(dayTimes) ? (
        <React.Fragment>
          {!!Text && (
            <span className="Opening__text">{this.getDayTimesString()}</span>
          )}
          {!!dayTimes[getDay] && (
            <span className="Opening__text--light">{dayTimes[getDay]}</span>
          )}
        </React.Fragment>
      ) : error ? null : (
        <span className="Opening__text">
          <p className="Opening__text--light">Loading</p>
        </span>
      );
    }

    return !_.isEmpty(dayTimes) ? (
      <Fragment>
        {!!dayTimes && (
          <div className={styles.weekTable}>
            {dayTimes.map((item) => {
              return item;
            })}
          </div>
        )}
      </Fragment>
    ) : error ? null : (
      <span className="Opening__text">
        <Loader />
      </span>
    );
  }
}

OpeningTimesWeek.propTypes = {
  url: PropTypes.string.isRequired,
  section: PropTypes.string,
  mode: PropTypes.string,
  Text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subPage: PropTypes.bool,
};

OpeningTimesWeek.defaultProps = {
  mode: "week",
  Text: "",
  section: "",
  subPage: false,
};

export default OpeningTimesWeek;
