const CAROUSEL_QUERY = (onName) => {
  return `
  fragment Carousel${onName} on EntryInterface {
    id
    title
    uri
    sectionHandle

    ...on competitions_competitions_Entry {
      ...Cardcompetitions_competitions_Entry
    }

    ...on eatDrink_eatDrink_Entry {
      ...CardeatDrink_eatDrink_Entry
    }

    ...on entertainment_entertainment_Entry {
      ...Cardentertainment_entertainment_Entry
    }

    ...on events_events_Entry {
      ...Cardevents_events_Entry
    }

    ...on experiences_experiences_Entry {
      ...Cardexperiences_experiences_Entry
    }

    ...on news_news_Entry {
      ...Cardnews_news_Entry
    }

    ...on offers_offers_Entry {
      ...Cardoffers_offers_Entry
    }

    ...on spa_spa_Entry {
      ...Cardspa_spa_Entry
    }

    ...on shops_shop_Entry {
      ...Cardshops_shop_Entry
    }

    ...on competitionsNewsEventsHomepage_competitionsNewsEventsHomepage_Entry {
      ...CardcompetitionsNewsEventsHomepage_competitionsNewsEventsHomepage_Entry
    }

    ...on casinoHomepage_casinoHomepage_Entry {
      ...CardcasinoHomepage_casinoHomepage_Entry
    }

    ...on cinemaHomepage_cinemaHomepage_Entry {
      ...CardcinemaHomepage_cinemaHomepage_Entry
    }

    ...on eatDrinkHomepage_eatDrinkHomepage_Entry {
      ...CardeatDrinkHomepage_eatDrinkHomepage_Entry
    }

    ...on shopHomepage_shopHomepage_Entry {
      ...CardshopHomepage_shopHomepage_Entry
    }

    ...on spaHomepage_spaHomepage_Entry {
      ...CardspaHomepage_spaHomepage_Entry
    }

    ...on entertainmentHomepage_entertainmentHomepage_Entry {
      ...CardentertainmentHomepage_entertainmentHomepage_Entry
    }

    ...on experiencesHomepage_experiencesHomepage_Entry {
      ...CardexperiencesHomepage_experiencesHomepage_Entry
    }

    ...on genericPages_genericPages_Entry {
      ...CardgenericPages_genericPages_Entry
    }
  }
`;
};

export default CAROUSEL_QUERY;
