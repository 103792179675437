import BUTTON_QUERY from "../SubLevel/Button";

/**
 * GraphQL query fragments for getting the global blocks data
 *
 * @param {string} onName The name of entry section to query
 */
const GLOBALBLOCKS_QUERY = (onName) => {
  return `
    fragment GlobalBlocks on ${onName} {
      globalBlocks {
        ...on globalBlocks_globalBlocks_BlockType {
          id
          entries {
            id
            ...on globalHalfImageText_globalHalfImageText_Entry {
              id
              globalHalfImageText {
                ...on globalHalfImageText_imageTextContent_BlockType {
                  id
                  heading
                  images {
                    id
                    url(transform:"globalHalf")
                    title
                  }
                  leftRight
                  body
                  ...ButtonglobalHalfImageText_imageTextContent_BlockType
                  backgroundImage {
                    id
                    url
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
    ${BUTTON_QUERY("globalHalfImageText_imageTextContent_BlockType")}
  `;
};

export default GLOBALBLOCKS_QUERY;
