import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import style from "./NotificationStrip.module.scss";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-icon-white.svg";

class NotificiationStrip extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { onClick, Text, Link, align } = this.props;
    return (
      <NavLink
        onClick={onClick}
        to={Link}
        className={cx(style.Notification, { [style.left]: align === "left" })}
      >
        {Text}
        <Chevron />
      </NavLink>
    );
  }
}

NotificiationStrip.propTypes = {
  onClick: PropTypes.func,
  Text: PropTypes.string.isRequired,
  Link: PropTypes.string.isRequired,
  align: PropTypes.string,
};

NotificiationStrip.defaultProps = {
  onClick() {},
  align: "center",
};

export default NotificiationStrip;
