/**
 * GraphQL query fragments for getting the action band data
 *
 * @param {string} onName The name of entry section to query
 */
const ACTIONBAND_QUERY = (onName) => {
  return `
  fragment ActionBand on ${onName} {
    actionBand {
      __typename
      ... on actionBand_phone_BlockType {
        phoneNumber
      }
      ... on actionBand_download_BlockType {
        DownloadURL {
          url
        }
        label
      }
      ... on actionBand_book_BlockType {
        bookingURL
      }
      ... on actionBand_filter_BlockType {
        filterLabel
      }
      ... on actionBand_sort_BlockType {
        sortOptions {
          fieldName
          fieldLabel
          order
        }
      }
      ... on actionBand_search_BlockType {
        findLabel
      }
      ... on actionBand_link_BlockType {
        linkText
        linkUrl
      }
      ... on actionBand_expander_BlockType {
        expanderLabel
      }
      ... on actionBand_clock_BlockType {
        label
      }
    }
  }`;
};

export default ACTIONBAND_QUERY;
