import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Chevron } from "../../Assets/icons/chevron-grey.svg";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="slick-arrow slick-next">
      <Chevron />
    </button>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  onClick: () => {},
};

export default NextArrow;
