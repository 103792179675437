import CARD_QUERY from "./Card";

const CAROUSEL_QUERY_FRAGMENTS = `
  ${CARD_QUERY("genericPages_genericPages_Entry")}
  ${CARD_QUERY("competitions_competitions_Entry")}
  ${CARD_QUERY("eatDrink_eatDrink_Entry")}
  ${CARD_QUERY("entertainment_entertainment_Entry")}
  ${CARD_QUERY("events_events_Entry")}
  ${CARD_QUERY("experiences_experiences_Entry")}
  ${CARD_QUERY("news_news_Entry")}
  ${CARD_QUERY("offers_offers_Entry")}
  ${CARD_QUERY("spa_spa_Entry")}
  ${CARD_QUERY("shops_shop_Entry")}
  ${CARD_QUERY(
    "competitionsNewsEventsHomepage_competitionsNewsEventsHomepage_Entry"
  )}
  ${CARD_QUERY("casinoHomepage_casinoHomepage_Entry")}
  ${CARD_QUERY("cinemaHomepage_cinemaHomepage_Entry")}
  ${CARD_QUERY("eatDrinkHomepage_eatDrinkHomepage_Entry")}
  ${CARD_QUERY("shopHomepage_shopHomepage_Entry")}
  ${CARD_QUERY("spaHomepage_spaHomepage_Entry")}
  ${CARD_QUERY("entertainmentHomepage_entertainmentHomepage_Entry")}
  ${CARD_QUERY("experiencesHomepage_experiencesHomepage_Entry")}
`;

export default CAROUSEL_QUERY_FRAGMENTS;
