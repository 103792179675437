/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import config from '../../../Config';
import styles from './Card.module.scss';
import OpeningTimesSingleTab from '../OpeningTimesSingleTab';
import Helpers from '../../../lib/HelperUtils';

const CardInner = props => {
  const {
    Image,
    ImageLink,
    Title,
    Content,
    Tag,
    TagTop,
    whiteBg,
    blueBg,
    linkLabel,
    onClick,
    onMouseDown
  } = props;
  const showTag = Tag === false ? TagTop : !TagTop;

  const img =
    typeof Image==='object'
      ? {
          url: Image.url,
          title: Image.title
        }
      : {
          url: Image,
          title: Title
        };

  const truncate = (str, max = 10) => {
    const arr = str.trim(' ').split(' ');
    const ellipsis = arr.length > max ? ' ...' : '';
    return arr.slice(0, max).join(' ') + ellipsis;
  };

  return (
    <React.Fragment>
      {ImageLink?(
        <div>
          <Link to={ImageLink.startsWith('/') ? ImageLink : `/${ImageLink}`} onClick={onClick} onMouseDown={onMouseDown}>
            <img className={styles.Card__Image} src={Helpers.transformUrlCheck(img.url)} alt={img.title} />
          </Link>
        </div>
      ):(
        <img className={styles.Card__Image} src={Helpers.transformUrlCheck(img.url)} alt={img.title} />
      )}
      <span
        className={cx(
          styles.Card__Tag,
          { [styles.disabled]: !TagTop },
          { [styles.Top]: TagTop }
        )}
      >
        {TagTop}
      </span>
      <div
        className={cx(
          styles.Card__Content,
          { [styles.whiteBg]: whiteBg },
          { [styles.blueBg]: blueBg }
        )}
      >
        <span className={cx(styles.Card__Tag, { [styles.disabled]: !showTag })}>
          {Tag}
        </span>
        <h3 className={styles.Card__Content__Title}>{ImageLink?(<Link to={ImageLink.startsWith('/') ? ImageLink : `/${ImageLink}`} onClick={onClick} onMouseDown={onMouseDown}>{Title}</Link>):Title}</h3>
        <div className={styles.Card__ContentWrap}>
          {Content?ReactHtmlParser(truncate(Content, 21)):''}
        </div>
        {linkLabel && (
          <span className={styles.Card__Content__Label}>{linkLabel}</span>
        )}
      </div>
    </React.Fragment>
  );
};

const Card = props => {
  const {
    TagTop,
    Slider,
    linkUrl,
    linkLabel,
    Image,
    Title,
    Content,
    Tag,
    whiteBg,
    blueBg,
    section,
    LeftAlign,
    onClick,
    onMouseDown,
    Classes,
    cardPage
  } = props;

  const timeSections = ['eatDrinkHomepage', 'shopHomepage'];

  if (linkUrl && linkUrl !== '/undefined' && (!Content || Content && Content.indexOf('<a')===-1)) {
    const endpointuri =
      linkUrl.indexOf('/') > -1 ? linkUrl : `single/${linkUrl}`;
    return (
      <Link
        to={linkUrl.startsWith('/') ? linkUrl : `/${linkUrl}`}
        className={cx(
          styles.Card,
          Classes,
          { [styles.TagTop]: TagTop },
          { [styles.Card__noSlider]: !Slider },
          { [styles.leftalign]: LeftAlign }
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <CardInner
          Image={Image}
          linkLabel={linkLabel}
          Title={Title}
          Content={Content}
          Tag={Tag}
          TagTop={TagTop}
          whiteBg={whiteBg}
          blueBg={blueBg}
        />
        {timeSections.includes(section) ? (
          <OpeningTimesSingleTab
            section={section}
            Text=''
            url={`${config.contentEndpoint}/hours/${endpointuri}.json`}
            mode='alt'
            subPage={cardPage}
          />
        ) : null}
      </Link>
    );
  } else {
    return (
      <div
        className={cx(
          styles.Card,
          { [styles.TagTop]: TagTop },
          { [styles.Card__noSlider]: !Slider },
          { [styles.leftalign]: LeftAlign }
        )}
      >
        <CardInner
          Image={Image}
          ImageLink={linkUrl && linkUrl !== '/undefined'?linkUrl:null}
          linkLabel={linkLabel}
          Title={Title}
          Content={Content}
          Tag={Tag}
          TagTop={TagTop}
          whiteBg={whiteBg}
          blueBg={blueBg}
          Classes={Classes}
          Slider={Slider}
          onClick={onClick}
          onMouseDown={onMouseDown}
        />
        {timeSections.includes(section) ? (
          <OpeningTimesSingleTab
            section={section}
            Text=''
            url={`${config.contentEndpoint}/hours/single/${Tag}.json`}
            mode='alt'
            subPage={cardPage}
          />
        ) : null}
      </div>
    );
  }
};

CardInner.propTypes = {
  Image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Title: PropTypes.string.isRequired,
  Content: PropTypes.string.isRequired,
  Tag: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  TagTop: PropTypes.bool,
  whiteBg: PropTypes.bool,
  blueBg: PropTypes.bool,
  linkLabel: PropTypes.string,
  imageLink: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func
};

CardInner.defaultProps = {
  Tag: false,
  TagTop: false,
  whiteBg: false,
  blueBg: false,
  linkLabel: PropTypes.string,
  Classes: '',
  Slider: true,
  ImageLink: '',
  Image: '',
  onClick: () => {},
  onMouseDown: () => {}
};

Card.propTypes = {
  Classes: PropTypes.string,
  TagTop: PropTypes.bool,
  Slider: PropTypes.bool,
  url: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  Image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Title: PropTypes.string,
  Content: PropTypes.string,
  whiteBg: PropTypes.bool,
  blueBg: PropTypes.bool,
  section: PropTypes.string,
  Tag: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  LeftAlign: PropTypes.bool,
  onClick: PropTypes.func,
  cardPage: PropTypes.bool
};

Card.defaultProps = {
  Classes: '',
  TagTop: false,
  Slider: true,
  url: '',
  linkLabel: '',
  Tag: false,
  whiteBg: false,
  blueBg: false,
  linkUrl: '',
  Image: '',
  Title: '',
  Content: '',
  section: '',
  LeftAlign: false,
  onClick: () => {},
  onMouseDown: () => {},
  cardPage: false
};

export default Card;
