import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { observer } from "mobx-react";
import moment from "moment";
import config from "../../../Config";
import styles from "./Today.module.scss";
import NotificationStrip from "../NotificationStrip";
import Carousel from "../../Utilities/Carousel";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-orange.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close-icon-black.svg";
import store from "../../../Stores/TodayStore";
import Loader from "../../Elements/Loader";
import OpeningTimesWeek from "../../Elements/OpeningTimesWeek";

@observer
class Today extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOpening: false,
      sections: [
        { heading: "Casino", section: "casinoHomepage" },
        { heading: "Eat & Drink", section: "eatDrinkHomepage" },
        { heading: "Entertainment", section: "entertainmentHomepage" },
        { heading: "Shops", section: "shopHomepage" },
        { heading: "Spa", section: "spaHomepage" },
      ],
      requestSent: false,
    };
  }

  componentDidMount = () => {
    this.getContent();
  };

  componentDidUpdate = () => {
    this.getContent();
  };

  getContent = () => {
    const { isOpen } = this.props;
    const { requestSent } = this.state;

    if (!requestSent && isOpen) {
      const { fetchContent } = store;
      fetchContent();
      this.updateRequestStatus();
    }
  };

  updateRequestStatus = () => {
    this.setState({
      requestSent: true,
    });
  };

  openAccordion = () => {
    const { state } = this;
    this.setState((prevState) => ({
      ...state,
      showOpening: !prevState.showOpening,
    }));
  };

  renderNotifications = (Notifications) => {
    const now = new Date().getTime();

    const activeNotifications = Notifications.filter((note) => {
      const { start, end } = note.notification[0];

      if (start && now < new Date(start).getTime()) {
        return false;
      }
      if (end && now > new Date(end).getTime()) {
        return false;
      }
      return true;
    });

    return activeNotifications.map((note, i) => {
      const { buttonEntry, buttonUrl, heading } = note.notification[0];
      const url =
        buttonEntry && buttonEntry.length > 0
          ? `/${buttonEntry[0].uri}`
          : buttonUrl;
      return (
        <NotificationStrip
          key={`notify${i}`}
          Text={heading}
          Link={url}
          onClick={this.onRedirect}
        />
      );
    });
  };

  /**
   * Filter Todays Events
   * @desc Filters the event data to get just the events which are on today
   * @param {array} Events The event data to be filtered
   */
  filterTodayEvents = (Events) => {
    const now = moment(); // Get the date/time now using momentjs

    // Get todays events - Filter Events
    const todaysEvents = Events.filter((event) => {
      const { startDate, endDate } = event;
      const start = startDate ? moment(new Date(startDate)) : undefined; // Get momentjs instance of start date
      const end = endDate ? moment(new Date(endDate)) : undefined; // Get momentjs instance of end date

      // Check if current date is within event dates
      if (moment(now).isBetween(start, end, "days", "[]")) {
        // check if event has event days defined
        if (event.eventDays.length) {
          const day = now.format("dddd").toLowerCase(); // Get day of the week
          const { eventDays } = event; // Get event days from event
          return eventDays.includes(day); // Return whether today is in the event days
        }
        return true; // Keep event in todays events as it's not been excluded
      }

      return false; // Remove event from todays events
    });

    return todaysEvents;
  };

  onRedirect = () => {
    const { OpenMenu } = this.props;
    OpenMenu();
  };

  render() {
    const { isOpen, toggleToday } = this.props;
    const { showOpening, sections } = this.state;
    const { Notifications, Events, Offers, status } = store;

    const buttonContent = (
      <Fragment>
        <div className={styles.Today__back_mobile}>
          <Chevron />
          <span>Menu</span>
        </div>
        <div className={styles.Today__back_desktop}>
          <Close />
        </div>
      </Fragment>
    );

    const button =
      typeof toggleToday === "function" ? (
        <button
          type="button"
          onClick={toggleToday}
          className={styles.Today__back}
        >
          {buttonContent}
        </button>
      ) : (
        <a href="/">
          <button type="button" className={styles.Today__back}>
            {buttonContent}
          </button>
        </a>
      );

    if (status !== "done") {
      return (
        <div className={cx(styles.Today, { [styles.active]: isOpen })}>
          {button}
          <div
            className={cx(styles.Today__Opening, {
              [styles.active]: showOpening,
            })}
          >
            <Loader />
          </div>
        </div>
      );
    }

    const TodayEvents = this.filterTodayEvents(Events);

    return (
      <div
        className={cx(styles.Today, {
          [styles.active]: isOpen,
          [styles.inline]: typeof toggleToday !== "function",
        })}
      >
        {button}
        {this.renderNotifications(Notifications)}
        <div
          className={cx(styles.Today__Opening, {
            [styles.active]: showOpening,
          })}
        >
          <div className={styles.Today__Opening_mobile}>
            <button
              type="button"
              onClick={this.openAccordion}
              className={cx(styles.Today__Opening_Title, {
                [styles.active]: showOpening,
              })}
            >
              <span>Today&#39;s Opening Times</span>
              <Chevron />
            </button>
          </div>
          <div className={styles.Today__Content}>
            <div
              className={cx(styles.Today__Accordion, {
                [styles.active]: showOpening,
              })}
            >
              <div className={styles.Today__Opening_desktop}>
                <h2 className={styles.Today__Opening_Title_desktop}>
                  <span>Today&#39;s Opening Times</span>
                </h2>
              </div>
              {sections.map((item, i) => {
                return (
                  <div
                    key={`hoursset${i}`}
                    className={cx(
                      styles.Today__Accordion_Wrap,
                      styles.todayTimes
                    )}
                  >
                    <span className={styles.Today__Accordion_Heading}>
                      <span>{item.heading}</span>
                    </span>
                    <span className={styles.Today__Accordion_Text}>
                      <span>
                        <OpeningTimesWeek
                          url={`${config.contentEndpoint}/hours/single/${item.section}.json`}
                          Text=""
                          mode="day"
                        />
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className={styles.Today__Carousel__Wrap}>
              <div className={styles.Today__Carousel__Single}>
                <Carousel
                  Title="Offers"
                  Content={Offers}
                  itemClicked={toggleToday && this.onRedirect}
                  Slider-dark-bg
                  perViewDefault={TodayEvents.length > 0 ? 1 : 3}
                  blueBg
                />
              </div>
              {TodayEvents.length > 0 ? (
                <div className={styles.Today__Carousel__Single}>
                  <Carousel
                    Title="What&#39;s On Today?"
                    Content={TodayEvents}
                    perViewDefault={1}
                    itemClicked={toggleToday && this.onRedirect}
                    blueBg
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Today.defaultProps = {
  toggleToday: null,
  OpenMenu: null,
};

Today.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleToday: PropTypes.func,
  OpenMenu: PropTypes.func,
};

export default Today;
