const GRADIENT_QUERY = (onName) => {
  return `
    fragment Gradient${onName} on ${onName} {
      gradient {
        ...on colourGradients_colourGradients_Entry {
          colourGradient {
            ... on colourGradient_gradient_BlockType {
              colourOne
              colourTwo
            }
          }
        }
      }
    }
  `;
};

export default GRADIENT_QUERY;
