import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./ContentWrap.module.scss";

const ContentWrap = (props) => {
  const { children, isWide, isExtraWide, filter, identifier } = props;

  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.Wrapper_Wide]: isWide,
        [styles.Wrapper_ExtraWide]: isExtraWide,
        [styles.filterWrap]: filter,
      })}
      {...(identifier !== "" ? { id: identifier } : null)}
    >
      {children}
    </div>
  );
};

ContentWrap.propTypes = {
  filter: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isWide: PropTypes.bool,
  isExtraWide: PropTypes.bool,
  identifier: PropTypes.string,
};

ContentWrap.defaultProps = {
  filter: false,
  isWide: false,
  isExtraWide: false,
  identifier: "",
};

export default ContentWrap;
