import { observer } from "mobx-react";
import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import RouteList from "./Routelist";
import GLOBALSTORE from "./Stores/GlobalStore";
import Footer from "./components/Global/Footer";
import GlobalHeader from "./components/Global/GlobalHeader";
import { client } from "./lib/API/ApolloAPI";
// import ATREEMOSTORE from './Stores/AtreemoStore';
import "./App.scss";

@observer
class MainApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: true,
    };
  }

  componentDidMount() {
    let { fetchContent } = GLOBALSTORE;
    fetchContent();
  }

  render() {
    const { content } = this.state;
    const { NavigationItems } = GLOBALSTORE;
    const { navigation, footer, socialMedia } = NavigationItems;

    if (content === false) {
      return null;
    }

    return (
      <ApolloProvider client={client}>
        <React.Fragment>
          <Router>
            <ScrollToTop>
              <GlobalHeader NavigationItems={navigation.navigation || []} />
              <RouteList />
              <Footer
                NavigationItems={footer.navigation || []}
                SocialLinks={socialMedia.socialMedia || []}
              />
            </ScrollToTop>
          </Router>
        </React.Fragment>
      </ApolloProvider>
    );
  }
}

MainApp.propTypes = {};

export default MainApp;
