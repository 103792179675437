import { observable, action, runInAction } from "mobx";
import storeUtils from "../lib/StoreUtils";
import queryUtils from "../lib/QueryUtils";

class OpeningHoursStore {
  @observable Content = {
    notifications: [],
  };
  @observable status = "pending"; // "pending" / "done" / "error"

  /**
   * Requests the data from the CraftQL API
   * Updates the stored content
   */
  @action.bound async fetchContent() {
    try {
      const sectionTypeQuery = `
      {
        sections: entries(search:"displayInDirectory:1") {
          id
          __typename
        }
      }
      `;
      const sectionTypesResults = await storeUtils.fetchPageData(
        sectionTypeQuery
      );

      const checkSections = sectionTypesResults.sections
        .map((item) => {
          return item.__typename;
        })
        .filter((value, i, self) => {
          return i === self.indexOf(value);
        });

      const previews = checkSections.map((n) => {
        return `...on ${n} {
  ...${n}CardPages
}`;
      });
      const cardPages = checkSections.map((n) => {
        return `${queryUtils.cardPagesQuery(n)}
`;
      });

      const query = `
{
  entries (search:"displayInDirectory:1") {
    id
    title
    url
    sectionHandle
    ${previews}
  }
  shops: entries(section:"shops", search:"displayInDirectory2:1") {
    id
    sectionHandle
    ...shops_shop_EntryCardPages
  }
  eatDrink: entries(section:"eatDrink", search:"displayInDirectory2:1") {
    id
    sectionHandle
    ...eatDrink_eatDrink_EntryCardPages
  }
}
${cardPages}
${queryUtils.cardPagesQuery("shops_shop_Entry")}
${queryUtils.cardPagesQuery("eatDrink_eatDrink_Entry")}
`;
      const content = await storeUtils.fetchPageData(query);

      runInAction(() => {
        this.status = "done";
        this.Content = [].concat(
          content.entries,
          content.eatDrink,
          content.shops
        );
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  }
}

const store = new OpeningHoursStore();

export default store;
