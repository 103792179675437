const BUTTON_QUERY = (onName) => {
  let query =
    onName === "contentPicker_buttonBlock_BlockType"
      ? `fragment Button${onName} on ${onName} {
      buttonText
      buttonEntry {
        id
        uri
      }
      buttonUrl
      buttonOpenInNewWindow
      mailtoUrl
    }`
      : `
    fragment Button${onName} on ${onName} {
      buttonText
      buttonEntry {
        id
        uri
      }
      buttonOpenInNewWindow
      buttonUrl
    }
  `;
  return query;
};

export default BUTTON_QUERY;
