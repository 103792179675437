import React, { useEffect, useRef, lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import Loader from "./components/Elements/Loader";
import ATREEMOSTORE from "./Stores/AtreemoStore";
// import LiveResModal from "./components/Elements/LiveResAPIModal";

// Main Navigation Pages
const Home = lazy(() => import("./Pages/Singles/Home"));
const MainPage = lazy(() => import("./Pages/Singles/MainPage"));
const CardPage = lazy(() => import("./Pages/Singles/CardPage"));
// const Experiences = lazy(() => import('./Pages/Singles/Experiences'));
// const EatDrink = lazy(() => import('./Pages/Singles/Eatdrink'));
//const Cinema = lazy(() => import('./Pages/Singles/Cinema'));
// const Shop = lazy(() => import('./Pages/Singles/Shop'));
// const Entertainment = lazy(() => import('./Pages/Singles/Entertainment'));
// const Casino = lazy(() => import('./Pages/Singles/Casino'));
// const Hotel = lazy(() => import('./Pages/Singles/Hotel'));
// const Spa = lazy(() => import('./Pages/Singles/Spa'));
// const PrivateEvents = lazy(() => import('./Pages/Singles/PrivateEvents'));
// const CompNewsEvents = lazy(() => import('./Pages/Singles/CompNewsEvents'));
// const Offers = lazy(() => import('./Pages/Singles/Offers'));

// Secondary Pages
const OpeningHours = lazy(() => import("./Pages/Singles/OpeningHours"));
const Search = lazy(() => import("./Pages/Search"));
const LostProperty = lazy(() => import("./components/Elements/LostProperty"));

// Account Pages
const Login = lazy(() => import("./Pages/Account/Login"));
const Signup = lazy(() => import("./Pages/Account/Signup"));
const Account = lazy(() => import("./Pages/Account/Account"));
const SignupThankYou = lazy(() => import("./Pages/Account/SignupThankYou"));
const PasswordReset = lazy(() => import("./Pages/Sub/PasswordReset"));
const Incentives = lazy(() => import("./Pages/Sub/incentives"));
const IncentiveThankYou = lazy(() =>
  import("./Pages/Account/IncentiveThankYou")
);
const GuestServices = lazy(() => import("./components/Global/GuestServices"));
const Directory = lazy(() => import("./components/Global/Directory"));
const Today = lazy(() => import("./components/Global/Today"));

// Footer Navigation Pages
const Contact = lazy(() => import("./Pages/Contact"));
const Newsletter = lazy(() => import("./Pages/Newsletter"));

// Sub Pages
const SubPage = lazy(() => import("./Pages/Sub/SubPage"));
const Travel = lazy(() => import("./Pages/Sub/Travel"));
const Job = lazy(() => import("./Pages/Sub/Job"));
const NewsSub = lazy(() => import("./Pages/Sub/NewsSub"));
const OffersSub = lazy(() => import("./Pages/Sub/OffersSub"));
const Film = lazy(() => import("./Pages/Sub/Film"));

//Thank you Pages
const ThankyouEnquiry = lazy(() =>
  import("./Pages/Sub/ThankyouPages/ThankyouEnquiry")
);

//Error Page
const BadPage = lazy(() => import("./Pages/404"));

// Subcomponents
const FilmListings = lazy(() => import("./components/Single/FilmListings"));

// Stores

const RouteList = () => {
  const debounced = useRef(
    debounce(() => {
      const { loggedIn } = ATREEMOSTORE;

      // Update session if user is logged in
      loggedIn && ATREEMOSTORE.updateSession();
    }, 5000)
  );

  const useLocationChange = () => {
    const { error } = ATREEMOSTORE;
    let location = useLocation();

    // On location change
    useEffect(() => {
      debounced.current();

      // reset errors on page change
      if (error !== "") {
        ATREEMOSTORE.error = "";
        ATREEMOSTORE.status = "pending";
      }

      // ga.send(['pageview', location.pathname])
    }, [error, location]);
  };
  useLocationChange();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* Main Navigation Pages */}
        {/* <Route
          path="/widget"
          exact
          render={() => (
            <LiveResModal bookingID="60cc106f-1d3b-492e-b8f1-eb6f1ee7fd2e" />
          )}
        /> */}
        <Route path="/" exact component={Home} />
        <Route
          path="/experiences"
          exact
          render={() => (
            <CardPage
              sectionName="experiencesHomepage"
              onName="experiencesHomepage_experiencesHomepage_Entry"
              cardSectionName="experiences"
              cardOnName="experiences_experiences_Entry"
            />
          )}
        />
        <Route
          path="/eat-drink"
          exact
          render={() => (
            <CardPage
              sectionName="eatDrinkHomepage"
              onName="eatDrinkHomepage_eatDrinkHomepage_Entry"
              cardSectionName="eatDrink"
              cardOnName="eatDrink_eatDrink_Entry"
              dirTwo
            />
          )}
        />
        <Route
          path="/cinema"
          exact
          render={() => (
            <MainPage
              sectionName="cinemaHomepage"
              onName="cinemaHomepage_cinemaHomepage_Entry"
              SubComponent={FilmListings}
              additionalFieldsQuery='schemaMarkup { value }'
            />
          )}
        />
        <Route
          path="/shop"
          exact
          render={() => (
            <CardPage
              sectionName="shopHomepage"
              onName="shopHomepage_shopHomepage_Entry"
              cardSectionName="shops"
              cardOnName="shops_shop_Entry"
              dirTwo
            />
          )}
        />
        <Route
          path="/entertainment"
          exact
          render={() => (
            <CardPage
              sectionName="entertainmentHomepage"
              onName="entertainmentHomepage_entertainmentHomepage_Entry"
              cardSectionName="entertainment"
              cardOnName="entertainment_entertainment_Entry"
            />
          )}
        />
        <Route
          path="/casino"
          exact
          render={() => (
            <MainPage
              sectionName="casinoHomepage"
              onName="casinoHomepage_casinoHomepage_Entry"
            />
          )}
        />
        <Route
          path="/hotel"
          exact
          render={() => (
            <MainPage
              sectionName="hotelHomepage"
              onName="hotelHomepage_hotelHomepage_Entry"
              subTabsPageOne={{
                heading: "Hotel",
                uri: "/hotel",
              }}
              subTabsPageTwo={{
                heading: "Spa",
                uri: "/spa",
              }}
              additionalFieldsQuery={'schemaMarkup { value }'}
            />
          )}
        />
        <Route
          path="/spa"
          exact
          render={() => (
            <MainPage
              sectionName="spaHomepage"
              onName="spaHomepage_spaHomepage_Entry"
              subTabsPageOne={{
                heading: "Hotel",
                uri: "/hotel",
              }}
              subTabsPageTwo={{
                heading: "Spa",
                uri: "/spa",
              }}
              additionalFieldsQuery={'schemaMarkup { value }'}
            />
          )}
        />
        <Route
          path="/private-events"
          exact
          render={() => (
            <MainPage
              sectionName="privateEventsHomepage"
              onName="privateEventsHomepage_privateEventsHomepage_Entry"
            />
          )}
        />
        <Route
          path="/competitions-news-events"
          exact
          render={() => (
            <CardPage
              sectionName="competitionsNewsEventsHomepage"
              onName="competitionsNewsEventsHomepage_competitionsNewsEventsHomepage_Entry"
              cardSectionName={["competitions", "news", "events"]}
              orFilter
              cardOnName={[
                "competitions_competitions_Entry",
                "news_news_Entry",
                "events_events_Entry",
              ]}
            />
          )}
        />

        <Route
          path="/faq"
          exact
          render={() => (
            <CardPage
              sectionName="faqHomepage"
              onName="faqHomepage_faqHomepage_Entry"
              cardSectionName="faqs"
              cardOnName="faqs_faqs_Entry"
            />
          )}
        />

        <Route
          path="/offers"
          exact
          render={() => (
            <CardPage
              sectionName="offersHomepage"
              onName="offersHomepage_offersHomepage_Entry"
              actionBand="Offers"
              cardSectionName="offers"
              orFilter
              cardOnName="offers_offers_Entry"
            />
          )}
        />

        <Route
          path="/careers"
          exact
          render={() => (
            <CardPage
              sectionName="careersHomepage"
              onName="careersHomepage_careersHomepage_Entry"
              cardSectionName="careers"
              cardOnName="careers_careers_Entry"
            />
          )}
        />

        <Route
          path="/news"
          exact
          render={() => (
            <CardPage
              sectionName="newsHomepage"
              onName="newsHomepage_newsHomepage_Entry"
              cardSectionName="news"
              cardOnName="news_news_Entry"
            />
          )}
        />

        <Route
          path="/events"
          exact
          render={() => (
            <CardPage
              sectionName="eventsHomepage"
              onName="eventsHomepage_eventsHomepage_Entry"
              cardSectionName="events"
              cardOnName="events_events_Entry"
            />
          )}
        />

        <Route
          path="/competitions"
          exact
          render={() => (
            <CardPage
              sectionName="competitionsHomepage"
              onName="competitionsHomepage_competitionsHomepage_Entry"
              cardSectionName="competitions"
              cardOnName="competitions_competitions_Entry"
            />
          )}
        />

        {/* Secondary Pages */}
        <Route path="/opening-hours" exact component={OpeningHours} />
        <Route path="/search" exact component={Search} />
        <Route
          path="/guest-services"
          exact
          render={() => <GuestServices isOpen />}
        />
        <Route path="/today" exact render={() => <Today isOpen />} />
        <Route path="/directory" exact render={() => <Directory isOpen />} />

        {/* Secondary Pages */}
        <Route path="/opening-hours" exact component={OpeningHours} />
        <Route path="/search" exact component={Search} />

        <Route
          path="/lost-property"
          exact
          render={() => (
            <MainPage
              sectionName="lostProperty"
              onName="lostProperty_lostProperty_Entry"
              SubComponent={LostProperty}
              additionalFieldsQuery={`formTitle
                successMessage
                errorMessage
                disclaimerText
                formAdditional
              `}
            />
          )}
        />

        {/* Thank You Pages */}
        <Route
          path="/thank-you-enquiry"
          exact
          sectionName="errorItems"
          onName="ErrorItems"
          component={ThankyouEnquiry}
        />

        {/* Account Pages */}
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/signup/:slug" exact component={Incentives} />
        <Route
          path="/organiser-incentives/:slug"
          exact
          component={Incentives}
        />
        <Route path="/my-account" exact component={Account} />
        <Route path="/signup-thank-you" exact component={SignupThankYou} />
        <Route
          path="/organiser-thank-you"
          exact
          component={IncentiveThankYou}
        />
        {/* <Route path="/enquiry-thank-you" exact component={EnquiryThankYou} />  */}
        <Route
          path="/my-account/reset-password"
          exact
          component={PasswordReset}
        />

        {/* Footer Navigation Pages */}
        <Route path="/contact-us" exact component={Contact} />
        <Route path="/newsletter" exact component={Newsletter} />
        <Route
          path="/vortex-newsletter"
          exact
          component={() => {
            return (
              <Newsletter
                brandID="GentingVortex"
                onName="vortexNewsletterSignup"
              />
            );
          }}
        />

        {/* Sub Pages */}
        <Route path="/travel" exact component={Travel} />
        <Route path="/job-position" exact component={Job} />
        <Route path="/news-sub" exact component={NewsSub} />
        <Route path="/offers-sub" exact component={OffersSub} />
        <Route path="/cinema/:slug" exact component={Film} />
        <Route path="/:section/:slug" exact component={SubPage} />
        <Route path="/:section" exact component={SubPage} />

        {/* Error pages */}
        <Route
          path="/not-found"
          sectionName="errorItems"
          onName="ErrorItems"
          component={BadPage}
        />
        <Route component={BadPage} />
      </Switch>
    </Suspense>
  );
};

export default RouteList;
