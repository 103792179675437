import cx from "classnames";
import throttle from "lodash/throttle";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Pagination from "react-js-pagination";
import { ReactComponent as Chevron } from "../../../Assets/icons/chevron-orange.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close-icon-black.svg";
import Helpers from "../../../lib/HelperUtils";
import store from "../../../Stores/DirectoryStore";
import Button from "../../Elements/Button";
import Loader from "../../Elements/Loader";
import Card from "../../Utilities/Card";
import ContentWrap from "../../Utilities/ContentWrap";
import NotificationStrip from "../NotificationStrip";
import styles from "./Directory.module.scss";
import "./Directory.scss";

@observer
class Directory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      MenuTitle: "Menu",
      requestSent: false,
      currentPage: 1,
      scrollTo: React.createRef(),
      incLoader: React.createRef(),
      directoryContent: React.createRef(),
      itemsPerPage: 6,
      sortOrder: "title~asc",
      windowWidth: window.innerWidth,
      loadingDelay: false,
      sortOptions: [
        {
          label: "A-Z",
          field: "title",
          order: "asc",
        },
        {
          label: "Z-A",
          field: "title",
          order: "desc",
        },
      ],
    };

    this.throttleWindow = throttle(() => {
      this.setWidth();
    }, 200);
  }

  componentDidMount() {
    const { directoryContent } = this.state;
    const { toggleDirectory } = this.props;

    window.addEventListener("resize", this.throttleWindow);
    if (directoryContent.current) {
      directoryContent.current.addEventListener("scroll", this.pageScrolled);
    }
    if (typeof toggleDirectory !== "function") {
      window.addEventListener("scroll", this.pageScrolled);
    }

    this.setWidth();
  }

  componentWillUnmount() {
    const { directoryContent, loadingDelay } = this.state;

    window.removeEventListener("resize", this.throttleWindow);
    if (loadingDelay) {
      clearTimeout(loadingDelay);
    }
    if (directoryContent.current) {
      directoryContent.current.removeEventListener("scroll", this.pageScrolled);
    }
    if (typeof toggleDirectory !== "function") {
      window.removeEventListener("scroll", this.pageScrolled);
    }
  }

  pageScrolled = () => {
    const { incLoader, loadingDelay } = this.state;
    if (
      !loadingDelay &&
      incLoader.current &&
      window.innerHeight > incLoader.current.getBoundingClientRect().top
    ) {
      this.setState({
        loadingDelay: setTimeout(() => {
          this.nextPage();
        }, 500),
      });
    }
  };

  nextPage = () => {
    const { currentPage } = this.state;
    if (currentPage) {
      this.setState({
        currentPage: currentPage + 1,
        loadingDelay: false,
      });
    }
  };

  setWidth = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  componentDidUpdate = () => {
    const { isOpen } = this.props;
    const { requestSent } = this.state;

    if (!requestSent && isOpen) {
      const { fetchContent } = store;
      fetchContent();
      this.updateRequestStatus();
    }
  };

  updateRequestStatus = () => {
    this.setState({
      requestSent: true,
    });
  };

  handleChange = (event) => {
    this.setState({
      sortOrder: event.target.value,
    });
  };

  onRedirect = () => {
    const { OpenMenu } = this.props;
    OpenMenu();
  };

  sortCards = (Content) => {
    const { sortOrder } = this.state;
    const sortvars = sortOrder.split("~");
    if (sortvars.length === 2) {
      const sortField = sortvars[0];
      const sortDirection = sortvars[1];

      return Content.slice().sort((a, b) => {
        switch (sortDirection) {
          case "desc":
            if (a[sortField] > b[sortField]) {
              return -1;
            }
            if (a[sortField] < b[sortField]) {
              return 1;
            }
            return 0;

          default:
            if (a[sortField] < b[sortField]) {
              return -1;
            }
            if (a[sortField] > b[sortField]) {
              return 1;
            }
            return 0;
        }
      });
    }
    return Content;
  };

  handlePageChange = (pageNumber) => {
    const { scrollTo } = this.state;

    if (scrollTo.current) {
      const element = scrollTo.current;
      const offset = window.innerWidth > 1400 ? 203 : 121;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }

    this.setState({
      currentPage: pageNumber,
    });
  };

  render() {
    const { isOpen, toggleDirectory } = this.props;
    const {
      sortby,
      sortOptions,
      MenuTitle,
      itemsPerPage,
      currentPage,
      windowWidth,
      incLoader,
      scrollTo,
      directoryContent,
    } = this.state;
    const { Content, status } = store;

    const buttonContent = (
      <Fragment>
        <div className={styles.Directory__back_mobile}>
          <Chevron />
          {MenuTitle}
        </div>
        <div className={styles.Directory__back_desktop}>
          <Close />
        </div>
      </Fragment>
    );

    const button =
      typeof toggleDirectory === "function" ? (
        <button
          type="button"
          onClick={toggleDirectory}
          className={styles.Directory__back}
        >
          {buttonContent}
        </button>
      ) : (
        <a href="/">
          <button type="button" className={styles.Directory__back}>
            {buttonContent}
          </button>
        </a>
      );

    if (status !== "done" || typeof Content === "undefined") {
      return (
        <div
          className={cx(styles.Directory, { [styles.active]: isOpen })}
          ref={directoryContent}
        >
          {button}
          <div className={styles.Directory__Content}>
            <Loader />
          </div>
        </div>
      );
    }

    let itemCount = 0;

    return (
      <div
        className={cx(styles.Directory, {
          [styles.active]: isOpen,
          [styles.inline]: typeof toggleDirectory !== "function",
        })}
        ref={directoryContent}
      >
        {button}
        <ContentWrap isExtraWide style={styles.Directory__holder}>
          <h2 className={styles.Directory__title}>Directory</h2>
          <div className={styles.Directory__Notification}>
            <NotificationStrip
              onClick={this.onRedirect}
              Text="Make An Enquiry"
              align="left"
              Link="/contact-us"
            />
          </div>
        </ContentWrap>

        <div className={styles.Directory__Content}>
          <ContentWrap isExtraWide>
            <div className={styles.Directory__Sort}>
              <Button
                onClick={this.onRedirect}
                label="Make An Enquiry"
                url="/contact-us"
                rounded
              />
              <div className={styles.Directory__Sort_wrap}>
                <span className={styles.Directory__Sort_label}>Sort By:</span>
                <select
                  className={styles.Directory__Sort_input}
                  type="select"
                  value={sortby}
                  onChange={this.handleChange}
                >
                  {Object.values(sortOptions).map((n, i) => (
                    <option key={`option-${i}`} value={`${n.field}~${n.order}`}>
                      {n.label}
                    </option>
                  ))}
                </select>
                <Chevron className="Nav__icon" />
              </div>
            </div>
            <div className={styles.Directory__Cards} ref={scrollTo}>
              {this.sortCards(Content).map((slide, index) => {
                if (slide) {
                  itemCount++;
                  return (
                    <Fragment key={index}>
                      {(windowWidth < 576 &&
                        itemsPerPage * (currentPage - 1) <= itemCount - 1 &&
                        itemsPerPage * currentPage > itemCount - 1) ||
                      (windowWidth >= 576 &&
                        itemsPerPage * currentPage > itemCount - 1) ? (
                        <div className="Directory__Card">
                          {!slide.preview[0].showTag ? (
                            <Card
                              Image={
                                slide.preview && slide.preview[0].image
                                  ? slide.preview[0].image[0]
                                  : "/static/media/RWB_logo.b9dc4249.png"
                              }
                              Title={
                                slide.preview[0].previewTitle
                                  ? slide.preview[0].previewTitle
                                  : slide.title
                              }
                              linkUrl={`/${slide.uri}`}
                              Content={
                                slide.preview
                                  ? slide.preview[0].shortDescription
                                  : ""
                              }
                              onClick={toggleDirectory && this.onRedirect}
                            />
                          ) : slide.tag ? (
                            <Card
                              Image={
                                slide.preview && slide.preview[0].image
                                  ? slide.preview[0].image[0]
                                  : "/static/media/RWB_logo.b9dc4249.png"
                              }
                              Title={
                                slide.preview[0].previewTitle
                                  ? slide.preview[0].previewTitle
                                  : slide.title
                              }
                              linkUrl={`/${slide.uri}`}
                              Content={
                                slide.preview
                                  ? slide.preview[0].shortDescription
                                  : ""
                              }
                              onClick={toggleDirectory && this.onRedirect}
                              Tag={
                                slide.preview[0].tag
                                  ? slide.preview[0].tag
                                  : slide.tag
                              }
                            />
                          ) : (
                            <Card
                              Image={
                                slide.preview && slide.preview[0].image
                                  ? slide.preview[0].image[0]
                                  : "/static/media/RWB_logo.b9dc4249.png"
                              }
                              Title={
                                slide.preview[0].previewTitle
                                  ? slide.preview[0].previewTitle
                                  : slide.title
                              }
                              linkUrl={`/${slide.uri}`}
                              Content={
                                slide.preview
                                  ? slide.preview[0].shortDescription
                                  : ""
                              }
                              onClick={toggleDirectory && this.onRedirect}
                              Tag={
                                slide.preview[0].tag
                                  ? slide.preview[0].tag
                                  : slide.sectionHandle
                                  ? Helpers.deCamelCase(slide.sectionHandle)
                                  : null
                              }
                            />
                          )}
                        </div>
                      ) : null}
                    </Fragment>
                  );
                }
                return null;
              })}
              {windowWidth < 576 ? (
                Content.length > itemsPerPage ? (
                  <div className={styles.paginationHolder}>
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={Content.length}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                      prevPageText={<Chevron className="nav-prev" />}
                      nextPageText={<Chevron className="nav-next" />}
                    />
                  </div>
                ) : null
              ) : currentPage < Math.min(Content.length / itemsPerPage) ? (
                <div ref={incLoader}>
                  <Loader />
                </div>
              ) : null}
            </div>
          </ContentWrap>
        </div>
      </div>
    );
  }
}

Directory.defaultProps = {
  toggleDirectory: null,
  OpenMenu: null,
};

Directory.propTypes = {
  OpenMenu: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  toggleDirectory: PropTypes.func,
};

export default Directory;
