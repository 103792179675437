import React from "react";
import { ReactComponent as Spinner } from "../../../Assets/icons/load-icon.svg";
import styles from "./Loader.module.scss";

const Loader = () => (
  <div className={styles.Loader}>
    <Spinner />
    <span>Loading...</span>
  </div>
);

export default Loader;
