import { observable, action } from "mobx";
import Atreemo from "../lib/API/Atreemo";

class AtreemoStore {
  @observable loggedIn = false;
  @observable SignupThanks = false;
  @observable customerDetails = {};
  @observable status = "pending"; // 'pending' /  'loading' / 'submitting' / 'done' / 'success' / 'error'
  @observable error = "";
  @observable PasswordResetStatus = "none"; // 'validating' / 'validated' / 'error' / 'done / 'none'

  constructor() {
    const atreemo = new Atreemo();
    this.loggedIn = atreemo.checkLoggedIn();
  }

  @action.bound
  SignupThanksReset() {
    this.SignupThanks = false;
  }

  @action.bound
  getCustomer() {
    const atreemo = new Atreemo();
    this.status = "loading";
    atreemo.getCustomer().then((response) => {
      this.customerDetails = response;
      this.status = "done";
      return true;
    }, this.onError);
  }

  @action.bound
  submitLogin(Email, Password) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo
      .submitLogin(Email, Password)
      .then(this.onSuccessLogin, this.onError);
  }

  @action.bound
  async updateSession() {
    const atreemo = new Atreemo();
    atreemo.refreshSession();
  }

  @action.bound
  submitLogout() {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.purgeSession().then(() => {
      atreemo.submitLogout().then(() => {
        this.loggedIn = false;
      }, this.onError);
    });
  }

  @action.bound
  submitSignup(values) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.submitSignup(values).then(this.onSuccessSignup, this.onError);
  }

  @action.bound
  async submitUpdate(valuesToUpdate) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    const results = await atreemo
      .submitUpdate(valuesToUpdate)
      .then(this.onSuccess, this.onError);
    this.getCustomer();
    return results;
  }

  @action.bound
  submitEnquiry(entries, corporate = false) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo
      .submitContact(entries, corporate)
      .then(this.onSuccess, this.onError);
  }

  @action.bound
  submitNewsletter(entries) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.submitNewsletter(entries).then(this.onSuccess, this.onError);
  }

  @action.bound
  resetEnquiry() {
    this.status = "pending";
  }

  @action.bound
  onSuccessLogin(data) {
    if (data.CustomerID && data.UserToken) {
      this.loggedIn = true;
      this.status = "done";
    } else {
      this.onError(data);
    }
  }

  @action.bound
  onSuccessSignup() {
    this.SignupThanks = true;
    this.loggedIn = true;
    this.status = "done";
  }

  @action.bound
  resetRequest(Email) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.resetRequest(Email).then(this.onResetRequest, this.onError);
  }

  @action.bound
  resetPassword(Token, Password, Password_confirm) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo
      .resetPassword(Token, Password, Password_confirm)
      .then(this.onResetSent, this.onError);
  }

  @action.bound
  resetValidate(Token) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.resetToken(Token).then(this.onResetValidated, this.onError);
  }

  @action.bound
  eventSignup(entries) {
    const atreemo = new Atreemo();
    this.status = "submitting";
    atreemo.eventSignup(entries).then(this.onSuccess, this.onError);
  }

  @action.bound
  onSuccess(response) {
    if (response.Error && response.Error.message) {
      this.onError(response);
    } else {
      this.status = "success";
    }
    return true;
  }

  @action.bound
  onResetRequest() {
    this.PasswordResetStatus = "validating";
    this.status = "success";
    return true;
  }

  @action.bound
  onResetSent() {
    this.PasswordResetStatus = "done";
    this.status = "success";
    return true;
  }

  @action.bound
  onResetValidated() {
    this.status = "validated";
    this.status = "success";
    return true;
  }

  @action.bound
  onError(response) {
    this.status = "error";
    if (response.Error && response.Error.message) {
      this.error = response.Error.message;
    } else {
      this.error = "Invalid details";
    }
    return false;
  }
}

const ATREEMOSTORE = new AtreemoStore();

export default ATREEMOSTORE;
