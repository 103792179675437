import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { Link, withRouter } from "react-router-dom";
import styles from "./SearchBar.module.scss";
import { ReactComponent as Search } from "../../../Assets/icons/search-icon-orange.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close-icon-black.svg";
import storeUtils from "../../../lib/StoreUtils";

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      sections: [],
      defaultFields: ["title", "uri"],
    };

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
  }

  onSuggestionsFetchRequested({ value: queryValue }) {
    const { sections, defaultFields } = this.state;

    this.setState({
      suggestions: [],
    });

    const onQuery = queryValue.replace(/[^a-zA-Z1-9,.]/gi, " ");

    if (onQuery.length > 2) {
      const sectionQueries = sections.map((item) => {
        if (item.customFields) {
          return `...on ${item.sectionType} {
            ${item.customFields.join(",")}
          }`;
        }
      });

      const query = `{
        entries(search:"title:'*${onQuery}*'") {
          ${sectionQueries}
          ${defaultFields.join(",")}
        }
      }`;

      (async () => {
        const content = await storeUtils.fetchAllData(query);
        const { value } = this.state;
        if (content && value === queryValue) {
          this.setState({
            suggestions: content,
          });
        }
      })();
    }
  }

  renderSuggestion = (suggestion, { query }) => {
    const { onToggleOpen } = this.props;
    const suggestionText = suggestion.title;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <Link
        to={`/${suggestion.uri}`}
        onClick={onToggleOpen}
        className={styles.SearchBar__Suggestion}
      >
        {parts.map((part, index) => {
          const className = part.highlight
            ? styles.SearchBar__SuggestionHightlight
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </Link>
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.title;
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onKeyDown = (e) => {
    const { history, onToggleOpen } = this.props;
    const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
    if (e.keyCode === 13 && value.trim() !== "") {
      history.push(`/search?q=${value}`);
      onToggleOpen();
      e.target.blur();
    }
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const { open, onToggleOpen } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Enter Keywords",
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onKeyUp: this.onKeyUp,
      className: styles.SearchBar__Field,
    };

    return (
      <div className={cx(styles.SearchBar, { [styles.active]: open })}>
        <div className={cx(styles.SearchBar__Holder)}>
          <div className={styles.SearchBar__Contents}>
            <div className={styles.SearchBar__Close}>
              <Close onClick={onToggleOpen} alt="Close" title="Close" />
            </div>

            <div className={styles.SearchBar__Label}>
              <Search />
              <span className={styles.SearchBar__LabelText}>
                Search Resorts World:
              </span>
            </div>

            <div className={styles.SearchBar__FieldHolder}>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                alwaysRenderSuggestions
                inputProps={inputProps}
                ref={this.storeInputReference}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchBar);
