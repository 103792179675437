import "whatwg-fetch";
import merge from "lodash/merge";
import xmlParser from "fast-xml-parser";

/**
 * class to extend error to hold the response
 */
export class ResponseError extends Error {
  constructor(response, ...params) {
    super(...params);

    this.response = response;
  }
}

/**
 * Check the response headers to ensure everything came back
 * fine, effectively does a check to ensure the status is
 * between 200 and 300
 */
export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  throw new ResponseError(response, response.statusText);
}

/**
 * Parse a response to ensure we're sending back JSON
 * and not a stream
 *
 * @param response
 * @returns {*|Promise<any>}
 */
export function parseJSON(response) {
  return response.json();
}

/**
 * Parse an XML response to ensure we're sending back JSON
 * and not a stream
 *
 * @param response
 * @returns {*|Promise<any>}
 */
export function parseXMLtoJSON(response) {
  return response.text().then((xml) => {
    const options = {
      attributeNamePrefix: "",
      attrNodeName: false, // default is false
      textNodeName: "#text",
      ignoreAttributes: false, // default is true
      ignoreNameSpace: false,
      allowBooleanAttributes: false,
      parseNodeValue: true,
      parseAttributeValue: true,
      trimValues: true,
      decodeHTMLchar: false,
      cdataTagName: "__cdata", // default is false
      cdataPositionChar: "\\c",
    };
    return xmlParser.parse(xml, options);
  });
}

/**
 * API Class, wrapped for request methods, by default:
 *  credentials is always set and sent as 'same-origin' for cookies
 *  Content-Type is always set to application/json
 *
 * This class SHALL make a pre-flight request to ensure the endpoint
 * supports the requested method.
 */
class ElementsAPI {
  constructor() {
    this.useCredentials = true;

    this.headers = {
      // 'app': config.app
    };
  }

  /**
   * Set whether we should send credentials through with
   * a request
   *
   * @param useCredentials
   */
  setUseCredentials(useCredentials) {
    this.useCredentials = useCredentials;
  }

  /**
   * Generic GET request wrapper, this method assumes you have already
   * URL encoded the request, and are passing that through to the
   * endpoint.
   *
   * @param endpoint
   * @returns {Promise<Response | never>}
   */
  requestGet(endpoint) {
    return fetch(endpoint, {
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge({}, this.headers),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  requestGetRaw(endpoint) {
    return fetch(endpoint, {
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge({}, this.headers),
    }).then(checkStatus);
  }

  /**
   * Generic GET request wrapper, this method assumes you have already
   * URL encoded the request, and are passing that through to the
   * endpoint.
   *
   * @param endpoint
   * @returns {Promise<Response | never>}
   */
  requestGetXML(endpoint) {
    return fetch(endpoint, {
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge({}, this.headers),
    })
      .then(checkStatus)
      .then(parseXMLtoJSON);
  }

  /**
   * Generic POST request wrapper, this method assumes you have send through an
   * object into the data parameter to be JSON encoded, and that the endpoint in use
   * supports JSON requests
   *
   * @param endpoint
   * @param data
   * @returns {Promise<Response | never>}
   */
  requestPost(endpoint, data) {
    return fetch(endpoint, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/json",
        },
        this.headers
      ),
      body: JSON.stringify(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  requestForm(endpoint, data) {
    var formData = new FormData();
    for (var name in data) {
      formData.append(name, data[name]);
    }
    return fetch(endpoint, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      body: formData,
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  requestPostFormData(endpoint, form) {
    return fetch(endpoint, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "multipart/form",
        },
        this.headers
      ),
      body: form,
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  /**
   * Generic PUT request wrapper, this method assumes you have send through an
   * object into the data parameter to be JSON encoded, and that the endpoint in use
   * supports JSON requests
   *
   * @param endpoint
   * @param data
   * @returns {Promise<Response | never>}
   */
  requestPut(endpoint, data) {
    return fetch(endpoint, {
      method: "PUT",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/json",
        },
        this.headers
      ),
      body: JSON.stringify(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  /**
   * Generic DELETE request wrapper, this method assumes that the DELETE request
   * requires no parameters, and all is handled via the URL.
   *
   * @param endpoint
   * @returns {Promise<Response | never>}
   */
  requestDelete(endpoint, data) {
    if (data) {
      return fetch(endpoint, {
        method: "DELETE",
        credentials: this.useCredentials ? "same-origin" : "anonymous",
        headers: merge(
          {
            "Content-Type": "application/json",
          },
          this.headers
        ),
        body: JSON.stringify(data),
      })
        .then(checkStatus)
        .then(parseJSON);
    }
    return fetch(endpoint, {
      method: "DELETE",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge({}, this.headers),
    })
      .then(checkStatus)
      .then(parseJSON);
  }
}

export default ElementsAPI;
