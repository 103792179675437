import CAROUSEL_QUERY from "../SubLevel/Carousel";
import GRADIENT_QUERY from "../SubLevel/Gradient";

/**
 * GraphQL query fragments for getting the carousel data
 *
 * @param {string} onName The name of entry section to query
 * @param {string} partName The name of the section part to query
 */
const CAROUSELSECTION_QUERY = (onName, partName) => {
  const fieldName = partName.replace(/^\w/, (c) => c.toLowerCase());
  const blockName = `${fieldName}_${fieldName}_BlockType`;
  return `
    fragment ${partName} on ${onName} {
      ${fieldName} {
        ...on ${blockName} {
          id
          heading
          entries {
            ...Carousel${blockName}
          }
          ...Gradient${blockName}
        }
      }
    }
    ${CAROUSEL_QUERY(`${blockName}`)}
    ${GRADIENT_QUERY(`${blockName}`)}
  `;
};

export default CAROUSELSECTION_QUERY;
