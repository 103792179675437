import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
// import { extendMoment } from 'moment-range';
import API from "../../lib/API/ElementsAPI";

class OpeningTimesSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TodaysTimes: "",
    };
  }

  componentDidMount() {
    const { state } = this;
    const { url } = this.props;
    const api = new API();

    // Request data from API
    api.requestGet(url).then((response) => {
      // Get opening times from response
      const { openingHours, openingExceptions, holidayDates, holidayOpeningHours } = response;

      // Get todays times
      this.getTodaysHours({
        openingHours,
        openingExceptions,
        holidayDates,
        holidayOpeningHours,
      }).then((result) => {
        const newState = {
          ...state,
          TodaysTimes: result
        }
        if (newState) {
          // Set todays times to state
          this.setState(newState);
        }
      });
    });
    // .catch(error => {
    //   console.log(error);
    // });
  }

  /**
   * Get the opening times for the current day
   *
   * @param {object} openingHours The standard opening times
   * @param {object} openingExceptions The opening times exceptions
   * @param {object} holidayDates The holiday dates
   * @param {object} holidayOpeningHours The holiday opening times
   */
  getTodaysHours = async ({
    openingHours,
    openingExceptions,
    holidayDates,
    holidayOpeningHours,
  }) => {
    const currentDate = new Date();
    const currentDay =
      currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1;
    let todaysTimes = {};

    // Get holiday times if there are holiday dates
    const holidayTimes = !_.isEmpty(holidayDates)
      ? this.isHolidayDate(holidayDates, currentDate)
      : false;

    const holidayOpen = Object.values(holidayOpeningHours)[currentDay].open;
    const holidayClose = Object.values(holidayOpeningHours)[currentDay].close;

    // Get exceptions
    const exceptions = !_.isEmpty(openingExceptions)
      ? this.getExceptions(openingExceptions, currentDate)
      : false;

    // Check for exceptions
    if (!_.isEmpty(exceptions)) {
      /* Set todays times to exception times if any */
      todaysTimes = exceptions[exceptions.length - 1]; // Get last exception entry
    } else if (
      Array.isArray(holidayTimes) &&
      holidayTimes.indexOf(true) !== -1 && // Today is a holiday date
      (holidayOpen || holidayClose) // Check for either an opening or closing time
    ) {
      /* Set todays times to holiday times if any */
      todaysTimes = Object.values(holidayOpeningHours)[currentDay];
    } else {

      /* Set to standard opening times */
      todaysTimes = Object.values(openingHours)[currentDay];
    }

    const openingTime = todaysTimes.open; // Todays opening time
    const closingTime = todaysTimes.close; // Todays closing time

    return this.getTodayTimesString(openingTime, closingTime);
  };

  /**
   * Gets any exceptions for the current date
   *
   * @param {array} exceptions The opening exceptions array
   * @param {string} currentDate The current date string
   */
  getExceptions(exceptions, currentDate) {
    return exceptions.filter((exception) =>
      moment(Date.parse(currentDate)).isSame(Date.parse(exception.date), "day")
    );
  }

  /**
   * Gets the string to be displayed as the opening times
   *
   * @param {string} open The opening time
   * @param {string} close The closing time
   */
  getTodayTimesString(open, close) {
    if (open && !close) {
      return `Opens ${open}`; // Display just opening time
    }
    if (!open && close) {
      return `Open Until ${close}`; // Display just closing time
    }
    if (open && close) {
      return `Opens ${open} to ${close}`; // Display opening and closing times
    }
    return "Closed"; // Display closed
  }

  /**
   * Check if current date if a holiday date
   *
   * @param {object} holidayDates The holiday dates
   * @param {string} currentDate The current date string
   */
  isHolidayDate(holidayDates, currentDate) {
    return Object.values(holidayDates).map((holiday) => {
      const startDate = moment(holiday.startDate, "YYYY MM DD");
      const endDate = moment(holiday.endDate, "YYYY MM DD");

      // Check if current date is within holiday dates
      return !!moment(currentDate).isBetween(startDate, endDate, "days", "[]");
    });
  }

  render() {
    const { TodaysTimes } = this.state;
    const { Text } = this.props;

    return !_.isEmpty(TodaysTimes) ? (
      <React.Fragment>
        {!!Text && <span className="Opening__text">{Text}</span>}
        {!!TodaysTimes && (
          <span className="Opening__text--light">{TodaysTimes}</span>
        )}
      </React.Fragment>
    ) : (
      <span className="Opening__text">
        <p>Loading...</p>
      </span>
    );
  }
}

OpeningTimesSingle.propTypes = {
  url: PropTypes.string.isRequired,
  Text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

OpeningTimesSingle.defaultProps = {
  Text: "Opening Times: ",
};

export default OpeningTimesSingle;
