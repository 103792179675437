import API from "./API/ApolloAPI";

class StoreUtils {
  fetchPageData = async (query) => {
    const api = new API();
    let data = [];

    //Request data from API
    await api.queryData(query).then((response) => {
      data = response.data;
    });
    // .catch(error => {
    //   console.log(error);
    // });
    return data;
  };

  abortController = new AbortController();

  fetchAllData = async (query) => {
    const api = new API();
    let data = [];

    if (this.abortController) {
      this.abortController.abort();
      delete this.abortController;
    }

    this.abortController = new AbortController();

    //Request data from API
    await api
      .cancellableQueryData(query, this.abortController)
      .then((response) => {
        data = response.data.entries;
      });
    // .catch(error => {
    //   console.log(error);
    // });
    return data;
  };
}

const storeUtils = new StoreUtils();

export default storeUtils;
