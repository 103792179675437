import { observable, action, runInAction } from "mobx";
import storeUtils from "../lib/StoreUtils";
import queryUtils from "../lib/QueryUtils";

class OpeningHoursStore {
  @observable Content = {
    notifications: [],
  };
  @observable status = "pending"; // "pending" / "done" / "error"

  /**
   * Requests the data from the CraftQL API
   * Updates the stored content
   */
  @action.bound async fetchContent() {
    try {
      const query = `
        {
          notifications : entries(section:["notifications"], limit:5) {
            ...on notifications_notifications_Entry {
              notification {
                ...on notification_notificationContent_BlockType {
                  heading
                  buttonEntry {
                    uri
                  }
                  buttonUrl
                  start @formatDateTime(format:"Y/m/d H:i:s")
                  end @formatDateTime(format:"Y/m/d H:i:s")
                }
              }
            }
          }
          offers : entries(section:["offers"], limit:5) {
            id
            ...offers_offers_EntryCardPages
          }
          events : entries(section:["events"], limit:5) {
            id
            ... on events_events_Entry {
              startDate @formatDateTime(format:"Y/m/d H:i:s")
              endDate @formatDateTime(format:"Y/m/d H:i:s")
              eventDays
            }
            ...events_events_EntryCardPages
          }
        }

        ${queryUtils.cardPagesQuery("events_events_Entry")}
        ${queryUtils.cardPagesQuery("offers_offers_Entry")}
      `;
      const content = await storeUtils.fetchPageData(query);

      runInAction(() => {
        this.status = "done";
        this.Events = content.events;
        this.Offers = content.offers;
        this.Notifications = content.notifications;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  }
}

const store = new OpeningHoursStore();

export default store;
