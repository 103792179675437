import "whatwg-fetch";
import merge from "lodash/merge";
import helpers from "../HelperUtils";

export class ResponseError extends Error {
  constructor(response, ...params) {
    super(...params);
    this.Error = response.Error;
  }
}

/**
 * Check the response headers to ensure everything came back
 * fine, effectively does a check to ensure the status is
 * between 200 and 300
 */
export async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const parsed = await response.json();

  //console.log(parsed);
  // Could not verify user
  if (parsed.Error.code === 206 || parsed.Error.code === 207) {
    const atreemo = new Atreemo();
    deleteSession("customerCreds"); // Remove user data
    atreemo.purgeSession(); // Remove token from server
  }

  // Email already in use
  if (parsed.Error.code === 303) {
    parsed.Error.message =
      'Email address already in use. <a href="/login">Login here</a> or use a different email address to signup.';
  }

  throw new ResponseError(parsed, response.statusText);
}

export function setSession(name, response) {
  sessionStorage[name] = JSON.stringify(response);
}

export function getSession(name) {
  let sessionData = sessionStorage[name];
  sessionData = sessionData ? JSON.parse(sessionStorage[name]) : false;
  return sessionData;
}

export function deleteSession(name) {
  sessionStorage.removeItem(name);
}

export function getUserSessionData() {
  const sessionData = getSession("customerCreds");
  const user = {
    CtcID: sessionData.CustomerID,
    UserToken: sessionData.UserToken,
  };

  return user;
}

/**
 * Parse a response to ensure we're sending back JSON
 * and not a stream
 *
 * @param response
 * @returns {*|Promise<any>}
 */
export function parseJSON(response) {
  return response.json();
}

class Atreemo {
  constructor() {
    this.useCredentials = true;
    this.headers = {
      // 'app': config.app
    };
  }

  checkLoggedIn() {
    const CtcID = getSession("customerCreds").CustomerID;
    return CtcID ? true : false;
  }

  getCustomer() {
    const userData = getUserSessionData("customerCreds");

    return fetch(`${process.env.ATREEMO_URL}/customer/get`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      // body: `CtcID=${CtcID}&UserToken=${UserToken}`
      body: helpers.objectToQuery(userData),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  submitLogin(Email, Password) {
    const data = {
      Email,
      Password,
    };

    return fetch(`${process.env.ATREEMO_URL}/customer/login`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((response) => {
        setSession("customerCreds", response);
        return response;
      });
  }

  submitLogout() {
    return new Promise((resolve) => {
      deleteSession("customerCreds");
      resolve();
    });
  }

  submitSignup(data) {
    data = helpers.getCleanObject(data);

    return fetch(`${process.env.ATREEMO_URL}/customer/signup`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((response) => {
        setSession("customerCreds", response);
      });
  }

  submitUpdate(itemsToUpdate) {
    const userData = getUserSessionData("customerCreds");
    let data = userData;

    data = helpers.getCleanObject(itemsToUpdate, data);

    // Object.keys(itemsToUpdate).forEach(item => {
    //   if (itemsToUpdate[item]) {
    //     data[item] = itemsToUpdate[item];
    //   }
    // });

    return fetch(`${process.env.ATREEMO_URL}/customer/update`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  refreshSession() {
    const userData = getUserSessionData("customerCreds");

    return fetch(`${process.env.ATREEMO_URL}/session/refresh`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(userData),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  purgeSession() {
    const userData = getUserSessionData("customerCreds");

    return fetch(`${process.env.ATREEMO_URL}/session/purge`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(userData),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  resetRequest(Email) {
    const data = {
      Email,
    };
    return fetch(`${process.env.ATREEMO_URL}/password/reset/request`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((response) => {
        return response;
      });
  }

  resetToken(Token) {
    const data = {
      Token,
    };
    return fetch(`${process.env.ATREEMO_URL}/password/reset/validate`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((response) => {
        return response;
      });
  }

  resetPassword(Token, Password, Password_confirm) {
    const data = {
      Token,
      Password,
      Password_confirm,
    };
    return fetch(`${process.env.ATREEMO_URL}/password/reset`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((response) => {
        return response;
      });
  }

  submitContact(entries, corporate = false) {
    let url = corporate
      ? `${process.env.ATREEMO_URL}/corporate/contact`
      : `${process.env.ATREEMO_URL}/contact`;
    const data = helpers.getCleanObject(entries);

    return fetch(url, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  submitNewsletter(entries) {
    let url = `${process.env.ATREEMO_URL}/newsletter`;
    const data = helpers.getCleanObject(entries);

    return fetch(url, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }

  eventSignup(entries) {
    const data = helpers.getCleanObject(entries);

    return fetch(`${process.env.ATREEMO_URL}/event/signup`, {
      method: "POST",
      credentials: this.useCredentials ? "same-origin" : "anonymous",
      headers: merge(
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        this.headers
      ),
      body: helpers.objectToQuery(data),
    })
      .then(checkStatus)
      .then(parseJSON);
  }
}

export default Atreemo;
