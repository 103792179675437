import { observable, action, runInAction } from "mobx";
import storeUtils from "../lib/StoreUtils";
import queryUtils from "../lib/QueryUtils";

class GuestServicesStore {
  @observable Content = {
    title: "",
    toggleHours: false,
    introduction: {},
    contentPicker: {},
    relatedPages: [],
  };
  @observable status = "pending"; // "pending" / "done" / "error"

  /**
   * Requests the data from the CraftQL API
   * Updates the stored content
   */
  @action.bound async fetchContent(section, sectionName) {
    runInAction(() => {
      this.status = "pending";
    });

    try {
      const sectionTypeQuery = `
      {
        section: entries(section:"${section}") {
          id
          ... on ${sectionName} {
            relatedField1 {
              id
              __typename
            }
            relatedField2 {
              id
              __typename
            }
          }
        }
      }
      `;
      const sectionTypes = await storeUtils.fetchPageData(sectionTypeQuery);

      const related1FieldTypes = sectionTypes.section[0].relatedField1
        .map((item) => {
          return item.__typename;
        })
        .filter((value, i, self) => {
          return i === self.indexOf(value);
        });

      const related2FieldTypes = sectionTypes.section[0].relatedField2
        .map((item) => {
          return item.__typename;
        })
        .filter((value, i, self) => {
          return i === self.indexOf(value);
        });

      const relatedSections = [].concat
        .apply(related1FieldTypes, related2FieldTypes)
        .filter((value, i, self) => {
          return i === self.indexOf(value);
        });

      const sectionData = queryUtils.sectionSpecificFields(sectionName);

      const previews1 = related1FieldTypes.map((n) => {
        return `...on ${n} {
  ...${n}CardPages
}`;
      });
      const previews2 = related2FieldTypes.map((n) => {
        return `...on ${n} {
  ...${n}CardPages
}`;
      });
      const cardPages = relatedSections.map((n) => {
        return `${queryUtils.cardPagesQuery(n)}
`;
      });

      const query = `
{
  entries(section:"${section}") {
    id
    ...on ${sectionName} {
      title
      relatedField1Title
      relatedField1 {
        id
        title
        sectionHandle
        ${previews1}
      }
      relatedField2Title
      relatedField2 {
        id
        title
        sectionHandle
        ${previews2}
      }
      ${sectionData}
    }
  }
}
${cardPages}
`;

      const content = await storeUtils.fetchPageData(query);

      runInAction(() => {
        this.status = "done";
        this.Content = content.entries[0];
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  }

  @action.bound async clearContent() {
    runInAction(() => {
      this.status = "pending";
      this.Content = {};
    });
  }
}

const store = new GuestServicesStore();

export default store;
