/* eslint-disable */
import React, { Component } from 'react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Slider from 'react-slick';
import cx from 'classnames';
import Card from '../Card';
import './Carousel.scss';
import PrevArrow from '../prevArrow';
import NextArrow from '../nextArrow';
import Button from '../../Elements/Button';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      isDragging: false,
      isMobile: false,
      isTablet: false,
      nav1: null,
      nav2: null,
      clientXonMouseDown: null,
      clientYonMouseDown: null
    };

    this.throttleWindow = throttle(() => {
      this.setState({
        isMobile: window.innerWidth < 576,
        isTablet: window.innerWidth < 992 && window.innerWidth > 576
      });
    }, 200);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      isMobile: window.innerWidth < 576,
      isTablet: window.innerWidth < 992 && window.innerWidth > 576,
      nav1: this.slider1,
      nav2: this.slider2
    });
    window.addEventListener('resize', this.throttleWindow);
  }

  componentWillReceiveProps(nextProps) {
    const { Content } = this.props;
    const { Content:nextContent } = nextProps;
    if(Content.length !== nextContent.length) {
      this.setState({
        nav1: null,
        nav2: null
      });
    }
  }

  componentDidUpdate() {
    const { nav1 } = this.state;
    if (!nav1) {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.throttleWindow);
  }

  toggleDragging = () => {
    let { isDragging } = this.state;
    if (isDragging) {
      setTimeout(function() {
        isDragging = !isDragging;
      }, 50);
    } else {
      isDragging = !isDragging;
    }
  };

  handleOnMouseDown = (e) => {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  };

  handleOnClick = (e) => {
    e.stopPropagation();
    const { clientXonMouseDown, clientYonMouseDown, isDragging } = this.state;
    const { itemClicked } = this.props;
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault()
    } else {
      if (!isDragging) {
        itemClicked();
        e.target.href ? (location.href = e.target.href) : null;
      } else {
        e.preventDefault();
      }
    }
  };

  onSort = sel => {
    let { scrollTo } = this.state;

    if (scrollTo && scrollTo.current) {
      const element = scrollTo.current;
      const offset = window.innerWidth > 1400 ? 203 : 121;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    this.setState({
      sortOrder: sel.currentTarget.value
    });
  };

  render() {
    let { isDragging, isMobile, isTablet } = this.state;
    let { perViewDefault } = this.props;
    let slidesPerPage = isMobile ? 1 : isTablet ? 2 : 3;
    if (perViewDefault) {
      slidesPerPage = Math.min(perViewDefault,slidesPerPage);
    }
    let slidesToScroll = slidesPerPage;
    let slidesToShow = slidesPerPage;
    let arrows = slidesPerPage === 3 ? true : false;
    const {
      Content,
      Title,
      CTA,
      darkBg,
      darkTitle,
      lightBg,
      CTABg,
      CTATextColour,
      draggable,
      LeftAlign,
      itemClicked,
      blueBg
    } = this.props;

    const Settings = {
      dots: false,
      draggable,
      infinite: Content.length > slidesToShow,
      arrows,
      speed: 500,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      slidesToShow,
      slidesToScroll,
      beforeChange: this.toggleDragging,
      afterChange: this.toggleDragging
    };

    const DotSettings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      centerPadding: '0px', //the default was 50px
      focusOnSelect: true
    };
    // Main docs referenced for new dot slider: https://react-slick.neostack.com/docs/example/as-nav-for/ , http://kenwheeler.github.io/slick/ and https://www.npmjs.com/package/react-slick

    const CTAStyle = {
      background: CTABg,
      color: CTATextColour,
      border: `1px solid ${CTABg}`
    };

    const CardStyle = !!(darkBg || lightBg);

    const CardWrap = {
      width: `calc(100% / ${Content.length})`,
      display: 'inline-block',
      fontSize: '0',
      verticalAlign: 'top'
    };

    return (
      <div className={cx('Slider__wrapper', { 'Slider-with-cta': CTA })}>
        <h2
          className={cx(
            'Slider__title',
            { 'Slider__title--white': darkBg },
            { 'Slider__title--dark': darkTitle }
          )}
        >
          {Title}
        </h2>
        <Slider
          {...Settings}
          className={cx({ 'Slider-dark-bg': darkBg })}
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          {Content.slice().map((slide, index) => {
            return _.isEmpty(slide.preview) ? null : (
              <Card
                key={index}
                Image={slide.preview[0].image[0]}
                Title={slide.preview[0].previewTitle === null
                  ? slide.title
                  : slide.preview[0].previewTitle}
                Content={
                  slide.preview[0].shortDescription
                    ? slide.preview[0].shortDescription
                    : ''
                }
                Tag={slide.preview[0].showTag ? slide.preview[0].tag === null
                  ? slide.tag
                  : slide.preview[0].tag : false}
                whiteBg={CardStyle}
                blueBg={blueBg}
                linkUrl={`/${slide.uri}`}
                LeftAlign={LeftAlign}
                onMouseDown={this.handleOnMouseDown}
                onClick={this.handleOnClick}
              />
            );
          })}
        </Slider>
        {Content.length > slidesToShow?(
          <Slider
            {...DotSettings}
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            className='slider__Custom-dot-container'
          >
            {Content.map(index => {
              return (
                <div
                  key={index}
                  className={cx('slider__Custom-dots', { 'black-dots': !darkBg })}
                >
                  <div className='slider__Custom-dot' />
                </div>
              );
            })}
          </Slider>
        ):null}
        {CTA ? CTA.url ? (
          <a href={CTA.url} style={CTAStyle} className={cx('Slider__CTA', { 'light_bg': !darkBg })} { ...{ onClick:CTA.onClick?CTA.onClick:null} }>
            {CTA.Text}
          </a>
        ) : <Button style={CTAStyle} label={CTA.Text} isFull isLink={false} classes={cx('Slider__CTA', { 'light_bg': !darkBg })} { ...{ onClick:CTA.onClick?CTA.onClick:null}} /> : null}
      </div>
    );
  }
}

Carousel.propTypes = {
  Content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  Title: PropTypes.string,
  darkBg: PropTypes.bool,
  darkTitle: PropTypes.bool,
  draggable: PropTypes.bool,
  blueBg: PropTypes.bool,
  lightBg: PropTypes.bool,
  CTA: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.object]),
  CTABg: PropTypes.string,
  CTATextColour: PropTypes.string,
  LeftAlign: PropTypes.bool,
  itemClicked: PropTypes.func,
  perViewDefault: PropTypes.oneOfType([PropTypes.number,PropTypes.object])
};

Carousel.defaultProps = {
  draggable: true,
  Title: '',
  darkBg: false,
  darkTitle: false,
  lightBg: false,
  blueBg: false,
  CTA: false,
  CTABg: '#CC3300',
  CTATextColour: '#FFFFFF',
  LeftAlign: false,
  itemClicked: () => {},
  perViewDefault: null
};

export default Carousel;
