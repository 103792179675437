/**
 * GraphQL query fragments for getting the introduction data
 *
 * @param {string} onName The name of entry section to query
 */
const INTRODUCTION_QUERY = (onName) => {
  return `
    fragment Introduction on ${onName} {
      introduction {
        ...on introduction_contentBlock_BlockType {
          image {
            url
            title
          }
          heading
          body
        }
      }
    }
  `;
};

export default INTRODUCTION_QUERY;
