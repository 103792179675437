import CARD_QUERY from "../SubLevel/Card";

/**
 * GraphQL query fragments for getting the card data for section pages
 *
 * @param {string} onName The name of entry section to query
 */
const CARDPAGES_QUERY = (onName) => {
  return `
    fragment ${onName}CardPages on ${onName} {
      id
      title
      uri
      ...Card${onName}
    }
    ${CARD_QUERY(onName)}
  `;
};

export default CARDPAGES_QUERY;
