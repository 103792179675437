import BUTTON_QUERY from "../SubLevel/Button";

/**
 * GraphQL query fragments for getting the getting here data
 *
 * @param {string} onName The name of entry section to query
 */
const GETTINGHERE_QUERY = (onName) => {
  return `
    fragment GettingHere on ${onName} {
      gettingHereContent {
        ...on gettingHereContent_travelContent_BlockType {
          heading
          body
          ...ButtongettingHereContent_travelContent_BlockType
        }
      }
    }
    ${BUTTON_QUERY("gettingHereContent_travelContent_BlockType")}
  `;
};

export default GETTINGHERE_QUERY;
