import PREVIEW_QUERY from "../SubLevel/Preview";

/**
 * GraphQL query fragments for getting the travel pages data
 *
 * @param {string} onName The name of entry section to query
 */
const TRAVELPAGES_QUERY = (onName) => {
  return `
    fragment TravelPages on ${onName} {
      travelPages {
        ...on travelPages_travelPages_BlockType {
          entry {
            id
            title
            url
            ...on gettingHere_gettingHere_Entry {
              priority
              ...PreviewgettingHere_gettingHere_Entry
            }
          }
        }
      }
    }
    ${PREVIEW_QUERY("gettingHere_gettingHere_Entry")}
  `;
};

export default TRAVELPAGES_QUERY;
