/**
 * GraphQL query fragments for getting the content picker data
 *
 * @param {string} onName The name of entry section to query
 */
const DEFAULTOPENINGHOURS_QUERY = () => {
  return `
    fragment DefaultOpeningHours on defaultOpeningHours_defaultOpeningHours_Entry {
      disclaimerFootnotes {
        identifier
        disclaimer
      }
      defaultHours {
        ...on defaultHours_hours_BlockType {
          id
          heading
          hours {
            day
            times
            disclaimerIdentifier
          }
        }
        ...on defaultHours_hoursHolidays_BlockType {
          id
          heading
          hours {
            day
            times
            disclaimerIdentifier
          }
          holidayText
          holidayHours {
            day
            times
            disclaimerIdentifier
          }
        }
        ...on defaultHours_textBlock_BlockType {
          id
          heading
          body
          buttonText
          buttonLink
          buttonOpenInNewWindow
        }
      }
    }
  `;
};

export default DEFAULTOPENINGHOURS_QUERY;
